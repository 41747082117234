const fixedInputClass =
  "rounded-md appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-main-color-darker focus:border-main-color-darker focus:z-10 sm:text-sm";

export default function Input({
  handleChange,
  value,
  labelText,
  labelFor,
  id,
  name,
  type,
  isRequired = false,
  placeholder,
  customClass,
  selectOptions,
  disabled
}) {
  return (
    <div className="my-3">
      <label htmlFor={labelFor} className="sr-only">
        {labelText}
      </label>

      {type === "textarea" ? (
        <textarea
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={type}
          required={isRequired}
          className={`${fixedInputClass} ${customClass}`}
          placeholder={placeholder}
          cols="50"
          rows="2"
          wrap="hard"
          maxLength="100"
        />
      ) : type === "select" ? (
        <select
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={type}
          required={isRequired}
          className={`${fixedInputClass} ${customClass}`}
          placeholder={placeholder}
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          
            {selectOptions.map((option, index) => {
              return (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              );
            })}
          
        </select>
      ) : (
        <input
          onChange={handleChange}
          value={value}
          id={id}
          name={name}
          type={type}
          required={isRequired}
          className={`${fixedInputClass} ${customClass}`}
          placeholder={placeholder}
        />
      )}
    </div>
  );
}
