import React from "react";

export const HeadToHeadIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="20"
      viewBox="0 0 32 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap=""
      strokeLinejoin=""
      className={`feather ${props.customCss}`}
    >
      <path d="M29.4,14v-1.3c0-1.5-1.2-2.7-2.7-2.7h-5.3c-1.5,0-2.7,1.2-2.7,2.7V14" />
      <circle cx="21.1" cy="4.7" r="2.7" />
      <path d="M13.3,14v-1.3c0-1.5-1.2-2.7-2.7-2.7H5.3c-1.5,0-2.7,1.2-2.7,2.7V14" />
      <circle cx="11" cy="4.7" r="2.7" />
    </svg>
  );
};
