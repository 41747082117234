import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { add } from "date-fns";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FormAction from "../components/UI/FormAction";
import FormValidation from "../components/UI/FormValidation";
import Input from "../components/UI/Input";
import { profileSettingsFields } from "../constants/formFields";
import TEAMS, { getFavTeamLogo } from "../constants/getTeams";
import UPDATE_USER from "../constants/updateUser";
import { useAuthContext } from "../context/AuthContext";
import { getToken, removeToken } from "../helpers";
import useCountdown from "../hooks/useCountdown";

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}
let backendApiUrl;
if (process.env.NODE_ENV === "development") {
  backendApiUrl = process.env.REACT_APP_API_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendApiUrl = process.env.REACT_APP_API_PROD;
}

const fields = profileSettingsFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

export const ProfileSettings = () => {
  const token = getToken();
  const navigate = useNavigate();
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER);
  const { user, isLoading, setUser } = useAuthContext();
  const [favoriteTeamLogo, setFavoriteTeamLogo] = useState("");
  const [profileSettingsError, setProfileSettingsError] = useState("");
  const [profileSettingsState, setProfileSettingsState] = useState(fieldsState);
  const [selectOptions, setSelectOptions] = useState([]);
  const [now, setNow] = useState("");
  const [nextGameStart, setNextGameStart] = useState("");
  const [teamChange, setTeamChange] = useState(false);

  const {
    data: dataTeams,
    loading: loadingTeams,
    error: errorTeams,
  } = useQuery(TEAMS);

  const handleLogout = () => {
    removeToken();
    navigate("/", { replace: true });
    setUser({});
  };

  let morningCron = useRef(null);
  let midday = useRef(null);

  useEffect(() => {
    morningCron.current = new Date();
    morningCron.current.setHours(8);
    morningCron.current.setMinutes(15);
    morningCron.current.setSeconds(0);
    morningCron.current.setMilliseconds(0);

    midday.current = new Date();
    midday.current.setHours(12);
    midday.current.setMinutes(0);
    midday.current.setSeconds(0);
    midday.current.setMilliseconds(0);

    setNow(new Date(midday.current).toISOString());
  }, []);

  const NEXT_GAME = gql`
query getNextGame{
  games(
    pagination: { limit: 1 },
    filters:
    {
      date_start: {
				gte: "${now}",
  		}
    }
  ) {
    data {
      id
      attributes{
        date_start
        gameID
      }
    }
  }
}
`;

  let countDownTime = useRef(null);

  const [
    getNextGame,
    { loading: loadingNextGame, error: errorNextGame, data: dataNextGame },
  ] = useLazyQuery(NEXT_GAME);

  useEffect(() => {
    getNextGame();
  }, [getNextGame]);
  useEffect(() => {
    if (dataNextGame && dataNextGame.games.data.length > 0) {
      setNextGameStart(dataNextGame.games.data[0].attributes.date_start);
    }
  }, [dataNextGame]);

  useEffect(() => {
    if (
      new Date() > morningCron.current &&
      new Date() < new Date(nextGameStart)
    ) {
      setTeamChange(true);
      countDownTime.current = nextGameStart;
    } else {
      setTeamChange(false);
      let tommorrowCron = add(morningCron.current, { days: 1 });
      countDownTime.current = tommorrowCron;
    }
  }, [nextGameStart, countDownTime]);

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div className={`w-12 show-counter text-sm flex mr-2 text-gray-600`}>
        <DateTimeDisplay value={hours} />
        <p>:</p>
        <DateTimeDisplay value={minutes} />
        <p>:</p>
        <DateTimeDisplay value={seconds} />
      </div>
    );
  };

  const DateTimeDisplay = ({ value, isDanger }) => {
    return (
      <div className="countdown">
        <p>{value}</p>
      </div>
    );
  };

  const [days, hours, minutes, seconds] = useCountdown(
    Date.parse(countDownTime.current)
  );

  let profileBio = user?.about;
  let taunt = user?.taunt;

  let nbaTeams = useMemo(() => [], []);
  if (!loadingTeams) {
    nbaTeams = dataTeams.teams.data;
  }

  useEffect(() => {
    let options = [];
    let userFavTeamLogo;

    nbaTeams.map((team) => {
      let teamObj = {
        name: team.attributes.team_name,
        value: team.attributes.teamID,
      };
      if (team.attributes.teamID === user?.favoriteTeamID) {
        userFavTeamLogo = team.attributes.team_logo.data.attributes.url;
      }
      options.push(teamObj);
    });
    setSelectOptions(options);
    setFavoriteTeamLogo(userFavTeamLogo);
  }, [nbaTeams, user?.favoriteTeamID]);

  const handleChange = (e) =>
    setProfileSettingsState({
      ...profileSettingsState,
      [e.target.id]: e.target.value,
    });

  const handleSubmit = (e) => {
    e.preventDefault();
    updateAccount(profileSettingsState);
  };

  //handle UPdate API Integration here
  const updateAccount = async (values) => {
    try {
      if (values.oldPassword !== "") {
        let changePassValues = {
          currentPassword: values.oldPassword,
          password: values.newPassword,
          passwordConfirmation: values.confirmPassword,
        };

        const response = await fetch(`${backendApiUrl}/auth/change-password`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(changePassValues),
        });

        const data = await response.json();
        if (data?.error) {
          throw data?.error;
        } else {
          setUser(data.user);
          handleLogout();
        }
      }

      if (
        values.favoriteTeam !== "" ||
        values.shortBio !== "" ||
        values.taunt !== ""
      ) {
        let updateUserObj = {};

        if (
          values.favoriteTeam !== "" &&
          values.shortBio !== "" &&
          values.taunt !== ""
        ) {
          updateUserObj = {
            id: user?.id,
            about: values.shortBio,
            taunt: values.taunt,
            favoriteTeamID: parseInt(values.favoriteTeam),
          };
          setFavoriteTeamLogo(
            getFavTeamLogo(nbaTeams, parseInt(values.favoriteTeam))
          );
          setUser((prevState) => ({
            ...prevState,
            favoriteTeamID: parseInt(values.favoriteTeam),
            taunt: values.taunt,
            about: values.shortBio,
          }));
        }
        if (
          values.favoriteTeam !== "" &&
          values.shortBio === "" &&
          values.taunt === ""
        ) {
          updateUserObj = {
            id: user?.id,
            favoriteTeamID: parseInt(values.favoriteTeam),
          };
          setFavoriteTeamLogo(
            getFavTeamLogo(nbaTeams, parseInt(values.favoriteTeam))
          );
          setUser((prevState) => ({
            ...prevState,
            favoriteTeamID: parseInt(values.favoriteTeam),
          }));
        }
        if (
          values.favoriteTeam === "" &&
          values.shortBio !== "" &&
          values.taunt === ""
        ) {
          updateUserObj = {
            id: user?.id,
            about: values.shortBio,
          };
          setUser((prevState) => ({
            ...prevState,
            about: values.shortBio,
          }));
        }
        if (
          values.favoriteTeam === "" &&
          values.shortBio === "" &&
          values.taunt !== ""
        ) {
          updateUserObj = {
            id: user?.id,
            taunt: values.taunt,
          };
          setUser((prevState) => ({
            ...prevState,
            taunt: values.taunt,
          }));
        }
        if (
          values.favoriteTeam === "" &&
          values.shortBio !== "" &&
          values.taunt !== ""
        ) {
          updateUserObj = {
            id: user?.id,
            about: values.shortBio,
            taunt: values.taunt,
          };
          setUser((prevState) => ({
            ...prevState,
            taunt: values.taunt,
            about: values.shortBio,
          }));
        }
        if (
          values.favoriteTeam !== "" &&
          values.shortBio === "" &&
          values.taunt !== ""
        ) {
          updateUserObj = {
            id: user?.id,
            taunt: values.taunt,
            favoriteTeamID: parseInt(values.favoriteTeam),
          };
          setFavoriteTeamLogo(
            getFavTeamLogo(nbaTeams, parseInt(values.favoriteTeam))
          );
          setUser((prevState) => ({
            ...prevState,
            favoriteTeamID: parseInt(values.favoriteTeam),
            taunt: values.taunt,
          }));
        }

        updateUser({
          context: {
            headers: {
              Authorization: `${process.env.REACT_APP_BEARER} ${token}`,
              "Content-Type": "application/json",
            },
          },
          variables: updateUserObj,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="">
      <div className="lg:flex py-16 items-center">
        <h2 className="text-2xl text-dark-color-font uppercase">
          Profile settings
        </h2>
      </div>

      <div className="w-full flex flex-row flex-wrap items-end sm:mb-9 border-b border-gray-300 border-solid"></div>

      <div className="py-12 w-full flex flex-row flex-wrap">
        <div className="w-full md:w-1/3">
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:pr-20 md:pr-5 mb-4">
              <img
                src={`${backendUrl}${favoriteTeamLogo}`}
                alt="favTeam"
                className="w-1/4 md:w-2/3 h-auto object-contain pb-1.5 mt-auto"
              />
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <form className="" onSubmit={handleSubmit}>
            <FormValidation errorMessage={profileSettingsError} />
            {fields.map((field) =>
              field.id === "favoriteTeam" ? (
                <div key="teamChange">
                  <div className="">
                    <h3>Set your favorite team</h3>
                    <p className="text-gray-600 text-sm">
                      Correct predictions invloving your team brings extra
                      points, wrong predictions however reduce your points
                      total.
                      <br />
                      You can change your favorite team as many times as you
                      like, we don't judge.
                    </p>
                    <p className="text-gray-600 text-sm">
                      You can't change favorite team after first game of the day
                      has started and until 8:15 in the morning the next day.
                    </p>
                    <div className="py-2 px-4 bg-white border-transparent border-b-4 border-neutral-500 flex mt-2">
                      {teamChange ? (
                        <span className="text-gray-600 text-sm mr-2">
                          Time left until Favorite Team select is disabled
                        </span>
                      ) : (
                        <span className="text-gray-600 text-sm mr-2">
                          Time left until Favorite Team select is enabled
                        </span>
                      )}
                      {!isNaN(hours) ? (
                        <ShowCounter
                          hours={hours}
                          minutes={minutes}
                          seconds={seconds}
                        />
                      ) : (
                        <span className="text-gray-600 text-sm mr-2">
                          ...loading
                        </span>
                      )}
                    </div>
                  </div>
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={profileSettingsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                    selectOptions={selectOptions}
                    disabled={teamChange ? false : true}
                  />
                  <hr className="h-px my-8 bg-gray-300 border-0"></hr>
                </div>
              ) : field.id === "oldPassword" ? (
                <div key="passwordChange">
                  <div className="">
                    <h3>Change your password</h3>
                    <p className="text-gray-600 text-sm">
                      Or don't, we know your password is "username123".
                    </p>
                  </div>
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={profileSettingsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={field.placeholder}
                  />
                </div>
              ) : field.id === "shortBio" ? (
                <div key="bioChange">
                  <div className="">
                    <h3>Set your bio</h3>
                    <p className="text-gray-600 text-sm">
                      Tell us about yourself or just write anything you want.
                    </p>
                  </div>
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={profileSettingsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={profileBio}
                  />
                  <hr className="h-px my-8 bg-gray-300 border-0"></hr>
                </div>
              ) : field.id === "taunt" ? (
                <div key="tauntChange">
                  <div className="">
                    <h3>Set your taunt</h3>
                    <p className="text-gray-600 text-sm">
                      If you take the lead this message will appear in The
                      playas page.
                    </p>
                  </div>
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={profileSettingsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={taunt}
                  />
                  <hr className="h-px my-8 bg-gray-300 border-0"></hr>
                </div>
              ) : field.id === "avatar" ? (
                <div key="fakeDiv">
                  {/*
                <div key="avatarChange">
                  <hr className="h-px my-8 bg-gray-300 border-0"></hr>
                  <div className="">
                    <h3>Set your Avatar</h3>
                  </div>
                  <Input
                    key={field.id}
                    handleChange={handleChange}
                    value={profileSettingsState[field.id]}
                    labelText={field.labelText}
                    labelFor={field.labelFor}
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    isRequired={field.isRequired}
                    placeholder={profileBio}
                    selectOptions={selectOptions}
                  />
                </div>
              */}
                </div>
              ) : (
                <Input
                  key={field.id}
                  handleChange={handleChange}
                  value={profileSettingsState[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.name}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                />
              )
            )}
            <FormAction
              handleSubmit={handleSubmit}
              text="Submit"
              extraClass="mt-10 w-1/5"
            />
          </form>
        </div>
      </div>
    </div>
  );
};
