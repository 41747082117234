import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { ListGames } from "../components/ListGames";
import TEAMS, { getFavTeamLogo } from "../constants/getTeams";
import { useAuthContext } from "../context/AuthContext";
import { SocketProvider, useSocket } from "../context/SocketProvider";
import { getToken } from "../helpers";

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

export const Profile = () => {
  const { user, isLoading, setUser } = useAuthContext();
  const token = getToken();
  const [teams, setTeams] = useState([]);
  const [favLogo, setFavLogo] = useState("");
  const {
    loading: loadingTeams,
    error: errorTeams,
    data: dataTeams,
  } = useQuery(TEAMS);

  useEffect(() => {
    let favTeamLogo = "";
    if (
      !isLoading &&
      dataTeams &&
      dataTeams.teams &&
      dataTeams.teams.data &&
      user
    ) {
      if (!loadingTeams) {
        setTeams(dataTeams.teams.data);
      }
      favTeamLogo = getFavTeamLogo(teams, user?.favoriteTeamID);
      setFavLogo(favTeamLogo);
    }
  }, [dataTeams, loadingTeams, teams, user, isLoading]);

  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      // Connect to the Socket.io server with the token as a query parameter
      //socket.auth = { token };
      //socket.connect();
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket, token]);

  return (
    <div className="">
      <div className="lg:flex py-10 items-center">
        {user?.favoriteTeamID ? (
          <>
            <div className="bg-white rounded-md shadow-sm px-6 py-3 flex items-center">
              <h2 className="text-2xl text-dark-color-font uppercase mr-6">
                Your favorite team
              </h2>
              <img
                className="h-14 m-auto"
                src={`${backendUrl}${favLogo}`}
                alt=""
              />
            </div>
          </>
        ) : (
          <></>
        )}

        <h3 className="lg:text-right text-2xl grow text-dark-color-font">
          Points: {user?.points}
        </h3>
      </div>

      <div className="w-full flex flex-row flex-wrap items-end sm:mb-9 border-b border-gray-300 border-solid"></div>
      <SocketProvider token={token}>
        <ListGames user={user} />
      </SocketProvider>
    </div>
  );
};
