import { useState } from "react";
import { signupFields } from "../constants/formFields";
import FormAction from "./UI/FormAction";
import Input from "./UI/Input";
import FormValidation from "./UI/FormValidation";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { setToken } from "../helpers";

let backendApiUrl
if(process.env.NODE_ENV === 'development') {
  backendApiUrl = process.env.REACT_APP_API_DEV
}
if(process.env.NODE_ENV === 'production') {
  backendApiUrl = process.env.REACT_APP_API_PROD
}


const fields = signupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {

  let totalUsers

  fetch(`${backendApiUrl}/users/count`)
  .then((response) => response.json())
  .then((data) => {
    totalUsers = data}
  );


  const navigate = useNavigate();
  const { setUser } = useAuthContext();
  const [singupError, setSignUpError] = useState("");

  const [signupState, setSignupState] = useState(fieldsState);

  const handleChange = (e) =>
    setSignupState({ ...signupState, [e.target.id]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(signupState);
    createAccount(signupState);
  };
  const passwordConfirmationValidation = (a, b) => {
    if (a !== b) {
      throw { message: "The password confirmation does not match." };
    }
  }
  const userLimitCheck  = (a,b) => {
    if(a >= b) {
      throw { message: "Max users limit reached." };
    }
  }

  //handle Signup API Integration here
  const createAccount = async (values) => {
    
    try {
      passwordConfirmationValidation(
        values.password,
        values.confirmPassword
      );
      userLimitCheck(
        totalUsers,
        8
      )
      const response = await fetch(`${backendApiUrl}/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        // set the token
        setToken(data.jwt);

        // set the user
        setUser(data.user);

        console.log(`Welcome to Tha Playas ${data.user.username}!`);

        navigate("/profile", { replace: true });
      }
    } catch (error) {
      console.error(error);

      setSignUpError(error.message);
    }
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <FormValidation errorMessage={singupError} />
      {fields.map((field) => (
        <Input
          key={field.id}
          handleChange={handleChange}
          value={signupState[field.id]}
          labelText={field.labelText}
          labelFor={field.labelFor}
          id={field.id}
          name={field.name}
          type={field.type}
          isRequired={field.isRequired}
          placeholder={field.placeholder}
        />
      ))}
      <FormAction handleSubmit={handleSubmit} text="Signup" />
    </form>
  );
}
