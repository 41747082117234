import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "../icons/ChevronDownIcon";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function DropDownMenu(props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md shadow-sm border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-dark-color-font shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {props.mainIcon}
          {props.menuTitle}
          <ChevronDownIcon aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {props.menuItems.map((menuItem, index) => {
            let innerMenuItems = Object.values(menuItem)[0];
            return (
              <div key={index} className="py-1">
                {innerMenuItems.map((innerItem, index) => {
                  return (
                    <Menu.Item key={index}>
                      {({ active }) =>
                        innerItem.menuItemTitle === "Sign Out" ? (
                          <Link
                            to="/"
                            onClick={innerItem.menuItemPath}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-dark-color-font",
                              "block px-4 py-2 text-sm inline-flex w-full items-center"
                            )}
                          >
                            {innerItem.menuItemIcon}
                            {innerItem.menuItemTitle}
                          </Link>
                        ) : (
                          <Link
                            to={innerItem.menuItemPath}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-dark-color-font",
                              "block px-4 py-2 text-sm inline-flex w-full items-center"
                            )}
                          >
                            {innerItem.menuItemIcon}
                            {innerItem.menuItemTitle}
                          </Link>
                        )
                      }
                    </Menu.Item>
                  );
                })}
              </div>
            );
          })}
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
