import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useRoutes,
} from "react-router-dom";

import SiteHeader from "./components/SiteHeader";
import { getToken } from "./helpers";
import Homepage from "./pages/Homepage";
import { Profile } from "./pages/Profile";
import { ProfileSettings } from "./pages/ProfileSettings";
import { ProfileStats } from "./pages/ProfileStats";
import { Rules } from "./pages/Rules";
import { SignUpPage } from "./pages/SignUpPage";
import ThePlayers from "./pages/ThePlayers";

import { ProtectedRoute } from "./components/ProtectedRoute";

const token = getToken();

//apollo client
let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

const client = new ApolloClient({
  uri: `${backendUrl}/graphql`,
  cache: new InMemoryCache({
    typePolicies: {
      Team: {
        keyFields: ["teamID"],
      },
      UsersPermissionsUser: {
        keyFields: ["username"],
      },
      Game: {
        keyFields: ["gameID"],
      },
      Prediction: {
        //keyFields: ["gameID"],
        merge(existing, incoming) {
          // Merge the fields you want to customize
          existing = existing || {};
          return {
            ...existing,
            ...incoming,
            // For example, if you want to merge specific nested fields:
            attributes: {
              ...existing.attributes,
              ...incoming.attributes,
            },
          };
        },
      },
    },
  }),
  credentials: "include",
  options: {
    reconnect: true,
    connectionParams: {
      headers: {
        Authorization: `${process.env.REACT_APP_BEARER} ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        origin: true,
        credentials: true,
      },
    },
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App p-5 container overflow-hidden 2xl:max-w-screen-xl">
        <SiteHeader />

        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/players" element={<ThePlayers />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/rules" element={<Rules />} />

          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile_settings"
            element={
              <ProtectedRoute>
                <ProfileSettings />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile_stats"
            element={
              <ProtectedRoute>
                <ProfileStats />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </ApolloProvider>
  );
}

export default App;
