import { configureStore } from "@reduxjs/toolkit";
import headToHeadSlice from "./HeadToHead/headToHeadBoolean";
import headToHeadPredictionSlice from "./HeadToHead/headToHeadPredictionPoints";
import headToHeadPredictionsSlice from "./HeadToHead/headToHeadPredictions";
import mainYoutubeVideoSlice from "./mainYoutubeVideoSlice";
import predictionSlice from "./predictionSlice";

export default configureStore({
  reducer: {
    predictions: predictionSlice,
    mainYtVideoLink: mainYoutubeVideoSlice,
    headToHeadBoolean: headToHeadSlice,
    headToHeadPredictionPoints: headToHeadPredictionSlice,
    headToHeadPredictions: headToHeadPredictionsSlice,
  },
});
