import { gql } from "@apollo/client";

const CREATE_PREDICTION = gql`
mutation createPrediction($dbID: Int!, $gameID: Int!, $userID: Int!, $predictionTeamID: Int! $date_start: DateTime!) {
  createPrediction(data: { 
  	dbID: $dbID,
    gameID: $gameID,
    userID: $userID,
    predictionTeamID: $predictionTeamID,
    date_start: $date_start,
    correctPrediction: false,
    predictionProcessed: false,
    points: null,
  }) {
    data {
      id
      attributes {
        dbID,
        gameID,
        userID,
        predictionTeamID,
        correctPrediction,
        predictionProcessed,
        date_start,
        points,
      }
    }
  } 
}
`
export default CREATE_PREDICTION