import { gql, useLazyQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthContext } from "../../context/AuthContext";
import { useSocket } from "../../context/SocketProvider";
import { setHeadToHeadBoolean } from "../../store/HeadToHead/headToHeadBoolean";
import { setHeadToHeadPredictions } from "../../store/HeadToHead/headToHeadPredictions";
import { HeadToHeadIcon } from "../icons/HeadToHeadIcon";
import { GameCardHeaderH2HContent } from "./GameCardHeaderH2HContent";

const H2HPREDS = gql`
  query GetPreds($gameID: Int!) {
    predictions(
      pagination: { page: 1, pageSize: 200 }
      filters: { gameID: { eq: $gameID } }
    ) {
      data {
        id
        attributes {
          dbID
          gameID
          userID
          predictionTeamID
        }
      }
    }
  }
`;

const GET_H2H = gql`
  query getHeadToHead {
    headToHeads(
      pagination: { page: 1, pageSize: 200 }
      filters: { h2hProcessed: { eq: false } }
    ) {
      data {
        attributes {
          gameID
          userID
          vsUserID
          betPoints
        }
      }
    }
  }
`;

const GET_FIN_H2H = gql`
  query getFinHeadToHead($gameID: Int!) {
    headToHeads(filters: { gameID: { eq: $gameID } }) {
      data {
        id
        attributes {
          gameID
          userID
          vsUserID
          betPoints
          correctH2h
        }
      }
    }
  }
`;

export const GameCardHeaderH2H = (props) => {
  const [socket] = useState(useSocket());
  const [gameHasHead2Head, setGameHasHead2Head] = useState(false);
  const [gameHasPred, setGameHasPred] = useState(false);
  const [h2hPreds, setH2hPreds] = useState({});
  const [h2hFinPreds, setH2hFinPreds] = useState({});
  const [h2hSetData, setH2hSetData] = useState(null);
  const [h2hBtnClickable, setH2hBtnClickable] = useState(true);
  const { user, isLoading, setUser } = useAuthContext();
  const storedHeadToHead = useSelector((state) => state.headToHeadBoolean);
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  const [getH2hPredictions, { loading: loadingPreds, data: dataPreds }] =
    useLazyQuery(H2HPREDS, {
      variables: { gameID: props.gameID },
    });
  const [
    getFinH2hPredictions,
    { loading: loadingFinPreds, data: dataFinPreds },
  ] = useLazyQuery(GET_FIN_H2H, {
    variables: { gameID: props.gameID },
  });
  const [disableH2h, setDisableH2h] = useState(false);
  const [availablePointsToBet, setAvailablePointsToBet] = useState(0);
  const storedHeadToHeadPredictionPoints = useSelector(
    (state) => state.headToHeadPredictionPoints
  );

  const [customCssStyle, setCustomCssStyle] = useState("");
  const [h2hVsBet, setH2hVsBet] = useState([]);

  const updateH2hVsBet = useCallback(
    (newH2hBet) => {
      if (
        h2hVsBet.some(
          (bet) =>
            bet.gameID === newH2hBet.gameID &&
            bet.vsUserID === newH2hBet.vsUserID
        )
      ) {
        // If it's not in the array, update the state
        setH2hVsBet((prevH2hVsBet) => [...prevH2hVsBet, newH2hBet]);
      }
    },
    [h2hVsBet]
  );

  useEffect(() => {
    /*
    if (props.gameID === h2hBeta.gameID && user?.id === h2hBeta.vsUserID) {
      setCustomCssStyle("stroke-red-500 cursor-pointer mr-2");
    }*/
    if (storedHeadToHeadPredictionPoints.availablePoints) {
      setAvailablePointsToBet(storedHeadToHeadPredictionPoints.availablePoints);
    } else {
      if (user) {
        setAvailablePointsToBet(user.availablePoints);
      }
    }
    if (storedHeadToHead) {
      storedHeadToHead.forEach((storeh2h) => {
        if (props.gameID === storeh2h.gameID) {
          setGameHasHead2Head(true);
        }
        if (props.gameID === storeh2h.gameID && user?.id === storeh2h.userID) {
          setGameHasPred(true);
        }
      });
    }
    if (props.gameHasH2h) {
      setGameHasHead2Head(true);
      setGameHasPred(true);
    }

    if (!loadingPreds && dataPreds) {
      setH2hPreds(dataPreds);
    }

    if (socket && user) {
      socket.on("headToHeadGames", (h2hPreds) => {
        h2hPreds.predictions.forEach((h2hPred) => {
          if (props.gameID === h2hPred.gameId) {
            setGameHasHead2Head(true);
            let pred = {
              userID: h2hPred.userId,
              gameID: h2hPred.gameId,
              headToHead: true,
              predIdentifier: `${h2hPred.userId}-${h2hPred.gameId}`,
            };

            // Dispatch the action with the pred object
            dispatch(setHeadToHeadBoolean(pred));
            if (isOpen) {
            }
          }
          if (user?.id === h2hPred.userId) {
          }
        });
      });

      socket.on("h2hBetRecieved", (h2hBet) => {
        updateH2hVsBet(h2hBet);
        if (props.gameID === h2hBet.gameID && user?.id === h2hBet.vsUserID) {
          setCustomCssStyle("stroke-main-color-darker cursor-pointer mr-2");
        }
      });
    }
  }, [
    socket,
    user?.id,
    user,
    props.gameID,
    props.gameHasH2h,
    storedHeadToHead,
    dispatch,
    loadingPreds,
    dataPreds,
    props.predictionID,
    isOpen,
    storedHeadToHeadPredictionPoints.availablePoints,
    gameHasHead2Head,
    gameHasPred,
    h2hVsBet,
    updateH2hVsBet,
  ]);

  function closeModal() {
    setIsOpen(false);
  }

  const [getH2hData, { loading: h2hLoading, data: h2hData }] =
    useLazyQuery(GET_H2H);

  useEffect(() => {
    getH2hData();
    getFinH2hPredictions();
    if (gameHasHead2Head && gameHasPred) {
      setCustomCssStyle("stroke-neutral-500 cursor-pointer mr-2");
    } else {
      setCustomCssStyle("stroke-neutral-300 cursor-default mr-2");
    }
    if (!h2hLoading && h2hData) {
      setH2hSetData(h2hData);
      h2hData.headToHeads.data.map((h2hSet) => {
        if (
          h2hSet.attributes.gameID === props.gameID &&
          h2hSet.attributes.vsUserID === user?.id
        ) {
          setCustomCssStyle("stroke-main-color-darker cursor-pointer mr-2");
        }
      });
    }
    if (!loadingFinPreds && dataFinPreds) {
      setH2hFinPreds(dataFinPreds);

      if (h2hFinPreds.headToHeads && props.gameFinished) {
        if (h2hFinPreds.headToHeads.data.length > 0) {
          h2hFinPreds.headToHeads.data.map((h2hFin) => {
            const allCorrectH2hNull = h2hFinPreds.headToHeads.data.every(
              (h2hFin) => h2hFin.attributes.correctH2h === null
            );
            const userCheck = h2hFinPreds.headToHeads.data.some(
              (h2hFin) => h2hFin.attributes.userID === user.id
            );
            if (allCorrectH2hNull) {
              setCustomCssStyle("stroke-neutral-300 cursor-default mr-2");
              setH2hBtnClickable(false);
            }
            if (!userCheck) {
              setCustomCssStyle("stroke-neutral-300 cursor-default mr-2");
              setH2hBtnClickable(false);
            }
          });
        } else {
          setCustomCssStyle("stroke-neutral-300 cursor-default mr-2");
          setH2hBtnClickable(false);
        }
      }
    }
  }, [
    dataFinPreds,
    gameHasHead2Head,
    gameHasPred,
    getFinH2hPredictions,
    getH2hData,
    h2hData,
    h2hFinPreds,
    h2hLoading,
    h2hSetData,
    loadingFinPreds,
    props.gameFinished,
    props.gameID,
    user.id,
  ]);
  /*
  useEffect(() => {
    if (!loadingPreds && dataPreds) {
      setH2hPreds(dataPreds);
    }

  }, [loadingPreds, dataPreds]);
*/
  const triggerH2hUI = () => {
    setIsOpen(true);
    getH2hPredictions();
  };

  let timeLeft = Date.parse(props.dateStart) - Date.now();

  return (
    <>
      {gameHasHead2Head && gameHasPred ? (
        <>
          {!props.gameFinished && timeLeft > 0 ? (
            <>
              <button onClick={triggerH2hUI}>
                <HeadToHeadIcon customCss={customCssStyle} />
              </button>
              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Go Head To Head!
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500 mb-2">
                              Bet your points to win against other players.
                            </p>
                            <p className="text-md mb-6">
                              Available points you can bet:{" "}
                              {availablePointsToBet}
                            </p>
                            <GameCardHeaderH2HContent
                              gameHasHead2Head={gameHasHead2Head}
                              predictionID={props.predictionID}
                              gameID={props.gameID}
                              h2hPreds={h2hPreds}
                              h2hSetData={h2hSetData}
                              dateStart={props.dateStart}
                              h2hFinPreds={h2hFinPreds}
                            />
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          ) : (
            <>
              {h2hBtnClickable ? (
                <button onClick={triggerH2hUI}>
                  <HeadToHeadIcon customCss={customCssStyle} />
                </button>
              ) : (
                <HeadToHeadIcon customCss={customCssStyle} />
              )}

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white p-4 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            Your Head To Head results!
                          </Dialog.Title>
                          <div className="mt-2">
                            {/*<p className="text-sm text-gray-500 mb-2">
                              Bet your points to win against other players.
          </p>*/}
                            <GameCardHeaderH2HContent
                              gameHasHead2Head={gameHasHead2Head}
                              predictionID={props.predictionID}
                              gameID={props.gameID}
                              h2hPreds={h2hPreds}
                              h2hSetData={h2hSetData}
                              gameFinished={props.gameFinished}
                              dateStart={props.dateStart}
                              h2hFinPreds={h2hFinPreds}
                            />
                          </div>

                          <div className="mt-4">
                            {/*<button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Got it, thanks!
      </button>*/}
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </>
          )}
        </>
      ) : (
        <HeadToHeadIcon customCss={customCssStyle} />
      )}
    </>
  );
};
