import {
  add,
  differenceInCalendarDays,
  format,
  isFuture,
  subDays,
} from "date-fns";
import React from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const dateFormatter = (date) => {
  return format(new Date(date), "dd. MM.");
};

const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);
  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));
  const ticks = [startDate.getTime()];
  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }
  ticks.push(endDate.getTime());
  return ticks;
};

const fillTicksData = (_ticks, data) => {
  const ticks = [..._ticks];
  const filled = [];
  let currentTick = ticks.shift();
  let lastData = null;
  for (const it of data) {
    if (ticks.length && it.date > currentTick && lastData) {
      filled.push({ ...lastData, ...{ date: currentTick } });
      currentTick = ticks.shift();
    } else if (ticks.length && it.date === currentTick) {
      currentTick = ticks.shift();
    }

    filled.push(it);
    lastData = it;
  }

  return filled;
};

export const StatsLineChart = (props) => {
  let now = new Date();
  now.setDate(now.getDate());
  now.setHours(1);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  const startDate = subDays(now, 4);
  const endDate = now;

  const domain = [(dataMin) => dataMin, () => endDate.getTime()];
  const ticks = getTicks(startDate, endDate, 5);
  const filledData = fillTicksData(ticks, props.data);

  const users = [...new Set(props.data.map((item) => item.username))]; // get a list of unique usernames

  const getColorForUser = (user) => {
    if (user === props.currentUser.username) {
      return "#02A0CC";
    } else {
      return "#a6a6a7";
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      payload.sort((b, a) => parseFloat(a.value) - parseFloat(b.value));
      return (
        <div>
          <p className="border-b border-gray-300 border-solid mb-2">{`${dateFormatter(
            payload[0].payload.date
          )}`}</p>
          {payload.map((pld) => (
            <div
              key={pld.name}
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: getColorForUser(pld.name),
              }}
            >
              <span style={{ marginRight: "10px" }}>{pld.name}</span>{" "}
              <span>
                {pld.value} {pld.dataKey}
              </span>
            </div>
          ))}
        </div>
      );
    }

    return null;
  };

  return (
    <div style={{ height: "36rem", width: "1240px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={800}
          height={400}
          data={props.data}
          margin={{
            top: 30,
            right: 10,
            left: -20,
            bottom: 5,
          }}
        >
          <XAxis
            dataKey="date"
            hasTick
            scale="time"
            tickFormatter={dateFormatter}
            type="number"
            domain={domain}
            ticks={ticks}
          />
          <YAxis />
          <CartesianGrid strokeDasharray="5 1" />
          <Tooltip
            wrapperStyle={{
              padding: "16px",
              background: "#ffffff",
              outline: "none",
              border: "1px solid #d3d3d3",
              borderRadius: "4px",
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
            content={<CustomTooltip />}
          />
          <Legend />
          {users.map((user) => (
            <Line
              key={user}
              type="monotone"
              dataKey="points"
              data={props.data.filter((item) => item.username === user)}
              stroke={getColorForUser(user)}
              name={user}
              strokeWidth={1.625}
              connectNulls={true}
              activeDot={{ stroke: "#ffffff", strokeWidth: 2, r: 7 }}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
