import React from "react";
import Signup from "../components/SignUp";

export const SignUpPage = () => {
  return (
    <div className="py-12 w-full flex flex-row flex-wrap">
      <div className="w-full md:w-1/3">
        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:pr-20 md:pr-5">
            <h2 className="text-2xl text-dark-color-font uppercase mb-8">
              Sign up!
            </h2>
            <Signup />
          </div>
        </div>
      </div>
      <div className="w-full md:w-2/3"></div>
    </div>
  );
};
