import { Navigate } from "react-router-dom";
import { getToken } from "../helpers";

export const ProtectedRoute = ({ children }) => {
  const token = getToken();
  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  }
  return children;
};