import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { RankLineChart } from "../components/elements/RankLineChart";
import { TeamsBarChart } from "../components/elements/TeamsBarChart";
import { TotalPredsChart } from "../components/elements/TotalPredsChart";
import TEAMS from "../constants/getTeams";
import { useAuthContext } from "../context/AuthContext";

export const ProfileStats = () => {
  const { user, isLoading, setUser } = useAuthContext();
  const [userID, setUserID] = useState(null);
  const [preds, setPreds] = useState([]);
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const [userRank, setUserRank] = useState(null);

  useEffect(() => {
    setUserID(user?.id);
  }, [user?.id]);

  const PREDICTIONS = gql`
query GetPreds {
  predictions(
    pagination: { limit: -1 }
    filters: {
      predictionProcessed: {
        eq: true
        },
      date_start: {
        gte: "2023-10-24T12:00:00.000Z"
      },
      userID: {
        eq: ${userID},
        }
      }
    ) {
    data {
      id,
      attributes {
        dbID,
        gameID,
        userID,
        predictionTeamID,
        gameID,
        date_start,
        teams,
        correctPrediction,
        predictionProcessed
      }
    }
  }
}
`;

  const USERS = gql`
    query getUsers {
      usersPermissionsUsers {
        data {
          id
          attributes {
            username
            stats
          }
        }
      }
    }
  `;

  const [getPreds, { loading: predLoading, error: predError, data: predData }] =
    useLazyQuery(PREDICTIONS);

  const [
    getTeams,
    { loading: teamsLoading, error: teamsError, data: teamsData },
  ] = useLazyQuery(TEAMS);

  const [
    getUsers,
    { loading: usersLoading, error: usersError, data: usersData },
  ] = useLazyQuery(USERS);

  useEffect(() => {
    if (user) {
      getPreds();
      getTeams();
      getUsers();
    }
  }, [user, getPreds, predData, getTeams, teamsData, getUsers, usersData]);

  useEffect(() => {
    if (predData) {
      const processedPreds = predData.predictions.data.filter(
        (pred) => pred.attributes.predictionProcessed
      );
      setPreds(processedPreds);
    }
    if (teamsData) {
      setTeams(teamsData.teams.data);
    }
    if (usersData) {
      setUsers(usersData.usersPermissionsUsers.data);
    }
  }, [predLoading, predData, teamsData, usersData]);

  useEffect(() => {
    users.map((user) => {
      if (user.attributes.stats !== null) {
        let rank;
        if (userID === parseInt(user.id)) {
          rank = user.attributes.stats.slice(-1)[0].rank;
          setUserRank(rank);
        }
      }
    });
  }, [users, userID]);

  if (predLoading || teamsLoading) return <p>Loading...</p>;
  if (predError || teamsError) return <p>Error...</p>;

  let correctPredsTotal = preds.filter(
    (pred) => pred.attributes.correctPrediction
  );

  return (
    <div className="">
      <div className="lg:flex py-16 items-center">
        <h2 className="text-2xl text-dark-color-font uppercase">Stats</h2>
      </div>

      <div className="w-full flex flex-row flex-wrap items-end sm:mb-9 border-b border-gray-300 border-solid"></div>
      <div className="py-12 w-full flex flex-row flex-wrap">
        <div className="w-full md:w-1/3">
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:pr-20 md:pr-5">
              <div className="text-2xl lg:text-2xl text-dark-color-font font-bold uppercase border-b border-gray-300 border-solid pb-2 flex justify-between">
                <span>Total:</span>
                <span>{preds.length}</span>
              </div>
              <div className="text-2xl lg:text-2xl text-dark-color-font font-bold uppercase border-b border-gray-300 border-solid py-2 flex justify-between">
                <span>Correct:</span>
                <span>{correctPredsTotal.length}</span>
              </div>
              <div className="text-2xl lg:text-2xl text-dark-color-font font-bold uppercase border-b border-gray-300 border-solid py-2 flex justify-between mb-4">
                <span>Wrong:</span>
                <span>{preds.length - correctPredsTotal.length}</span>
              </div>
              <p>
                Statistics are a fundamental tool for the outcome of your
                predictions, and they play a critical role in helping you make
                informed decisions. By analyzing your prediciton patterns, you
                gain a deeper understanding of the factors that contribute to
                the outcome of a game. This information can be used to predict
                the likelihood of certain outcomes and adjust your prediction
                making strategies accordingly.
              </p>
              <p>Thanx Chat GPT ;)</p>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-row flex-wrap md:w-2/3">
          <div className="w-full md:w-1/2 px-4 grow">
            <h3 className="text-2xl lg:text-2xl mb-4 text-dark-color-font font-bold uppercase">
              Total predictions success rate
            </h3>
            <TotalPredsChart preds={preds} />
          </div>
          <div className="w-full md:w-1/2 px-4 grow">
            <h3 className="text-2xl lg:text-2xl mb-4 text-dark-color-font font-bold uppercase">
              Last 10 predictions success rate
            </h3>
            <TotalPredsChart preds={preds.slice(1).slice(-10)} />
          </div>
        </div>
      </div>
      <div className="py-12 w-full flex flex-row flex-wrap ">
        <h3 className="text-2xl lg:text-2xl mb-4 text-dark-color-font font-bold uppercase w-full">
          Prediction success rate - every team
        </h3>
        <TeamsBarChart teams={teams} preds={preds} />
      </div>
      <div className="py-12 w-full flex flex-row flex-wrap ">
        <h3 className="text-2xl lg:text-2xl mb-4 text-dark-color-font font-bold uppercase w-full">
          Your rank - {userRank}
        </h3>
        {userRank > 1 ? (
          <p>Are you really that bad?</p>
        ) : (
          <p>You are actually good, congrats!</p>
        )}
        <RankLineChart users={users} user={user} />
      </div>
    </div>
  );
};
