import { createSlice } from "@reduxjs/toolkit";

export const mainYoutubeVideoSlice = createSlice({
  name: "mainYtVideoLink",
  initialState: '',
  reducers: {
    addMainYtVideoLink: (state, action) => {
      const mainYtVideoLink = action.payload

      return [...state, mainYtVideoLink];
    },
  },
});


// this is for dispatch

export const { addMainYtVideoLink } = mainYoutubeVideoSlice.actions;

// this is for configureStore
export default mainYoutubeVideoSlice.reducer;
