import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import TEAMS, { getFavTeamLogo } from "../constants/getTeams";

const USERS = gql`
  query getUsers {
    usersPermissionsUsers {
      data {
        id
        attributes {
          username
          points
          about
          taunt
          favoriteTeamID
        }
      }
    }
  }
`;

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

const ThePlayers = () => {
  const [profiles, setProfiles] = useState([]);
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [leader, setLeader] = useState({});
  const { loading, error, data } = useQuery(USERS);
  const {
    loading: loadingTeams,
    error: errorTeams,
    data: dataTeams,
  } = useQuery(TEAMS);

  useEffect(() => {
    let usersArr = [];
    if (!loading) {
      if (!loadingTeams) {
        setTeams(dataTeams.teams.data);
      }
      setProfiles(data.usersPermissionsUsers.data);
      profiles.map((profile) => {
        let favTeamLogo = getFavTeamLogo(
          teams,
          profile.attributes.favoriteTeamID
        );
        let user = {
          id: profile.id,
          username: profile.attributes.username,
          points: profile.attributes.points,
          about: profile.attributes.about,
          taunt: profile.attributes.taunt,
          favoriteTeamLogo: favTeamLogo,
        };
        usersArr.push(user);
      });
      setUsers(usersArr);
    }
  }, [data, loading, profiles, dataTeams, loadingTeams, teams]);

  users.sort((b, a) => parseFloat(a.points) - parseFloat(b.points));

  useEffect(() => {
    setLeader(users[0]);
  }, [users]);

  return (
    <div className="">
      <div className="lg:flex py-16 items-center">
        <h2 className="text-2xl text-dark-color-font uppercase">Leaderboard</h2>
      </div>

      <div className="w-full flex flex-row flex-wrap items-end sm:mb-9 border-b border-gray-300 border-solid"></div>

      <div className="py-12 w-full flex flex-row flex-wrap">
        <div className="w-full lg:w-1/3">
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:pr-20 md:pr-5">
              <p className="mb-6 font-bold xl:text-xl">
                Message from the supreme leader:
              </p>
              <div className="text-4xl md:text-5xl xl:text-6xl text-dark-color-font uppercase mb-8 lg:mb-0 font-poppins font-black">
                {leader?.taunt}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-2/3">
          <div className="bg-white rounded-xl md:rounded-lg shadow-md">
            {users.map((user, index) => (
              <div
                key={`${user.id}_${index}`}
                className="py-6 w-full flex flex-row flex-wrap items-start border-b border-neutral-200 last:border-transparent"
              >
                <div className="w-1/3 md:w-1/4 md:m-auto">
                  <img
                    className="w-3/4 h-auto py-0 px-4 md:px-8 md:py-4 max-h-48 m-auto"
                    src={`${backendUrl}${user.favoriteTeamLogo}`}
                    alt=""
                  />
                </div>
                <div className="w-2/3 md:w-3/4 flex flex-col pr-8">
                  <div className="flex flex-col sm:flex-row sm:self-start mb-2 pb-2 w-full">
                    <div className="text-4xl lg:text-5xl text-dark-color-font font-poppins font-extrabold">
                      {user.points}
                    </div>
                    <div className="text-2xl lg:text-3xl text-dark-color-font font-poppins font-extrabold uppercase sm:pl-8">
                      {user.username}
                    </div>
                  </div>
                  <div className="max-h-24 overflow-hidden">
                    <ReactMarkdown className="line-break text-dark-color-font text-lg">
                      {user.about}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThePlayers;
