import { createSlice } from "@reduxjs/toolkit";

export const headToHeadPredictionSlice = createSlice({
  name: "headToHeadPredictionPoints",
  initialState: [],
  reducers: {
    setHeadToHeadPredictionPoints: (state, action) => {
      const userReservedAvailablePoints = {
        userID: action.payload.userID,
        availablePoints: action.payload.availablePoints,
      };
      return userReservedAvailablePoints;
    },
  },
});

export const { setHeadToHeadPredictionPoints } =
  headToHeadPredictionSlice.actions;
export default headToHeadPredictionSlice.reducer;
