import { createSlice } from "@reduxjs/toolkit";

export const headToHeadPredictionsSlice = createSlice({
  name: "headToHeadPredictions",
  initialState: [],
  reducers: {
    setHeadToHeadPredictions: (state, action) => {
      const h2hPrediction = {
        userID: action.payload.userID,
        gameID: action.payload.gameID,
        betPoints: action.payload.betPoints,
        vsUserID: action.payload.vsUserID,
      };

      // Instead of modifying state directly, return the new state
      return [...state, h2hPrediction];
    },
  },
});

export const { setHeadToHeadPredictions } = headToHeadPredictionsSlice.actions;
export default headToHeadPredictionsSlice.reducer;
