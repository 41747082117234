import { useState } from 'react';
import { loginFields } from "../constants/formFields";
import FormAction from "./UI/FormAction";
import Input from "./UI/Input";
import FormValidation from './UI/FormValidation';

import { useNavigate } from "react-router-dom"
import { useAuthContext } from '../context/AuthContext'
import { setToken } from "../helpers";

let backendApiUrl
if(process.env.NODE_ENV === 'development') {
  backendApiUrl = process.env.REACT_APP_API_DEV
}
if(process.env.NODE_ENV === 'production') {
  backendApiUrl = process.env.REACT_APP_API_PROD
}

const fields=loginFields;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');

export default function Login(){

    const navigate = useNavigate();
    const { setUser } = useAuthContext();
    const [loginError, setLoginError] = useState('');
    

    const [loginState,setLoginState]=useState(fieldsState);

    const handleChange=(e)=>{
        setLoginState({...loginState,[e.target.id]:e.target.value})
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        authenticateUser(loginState);
    }

    //Handle Login API Integration here
    const authenticateUser = async (values) =>{
        try {
            const value = {
              identifier: values.email,
              password: values.password,
            };
            const response = await fetch(`${backendApiUrl}/auth/local`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(value),
            });
      
            const data = await response.json();
            if (data?.error) {
              throw data?.error;
            } else {
              // set the token
              setToken(data.jwt);
      
              // set the user
              setUser(data.user);
      
              console.log(`Welcome back ${data.user.username}!`);
      
              navigate("/profile", { replace: true });

            }
          } catch (error) {
            console.error(error);
            setLoginError('Invalid E-mail or password')
          }
    }




    return(
        <form className="" onSubmit={handleSubmit}>
          <FormValidation errorMessage={loginError}/>
            {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                            customClass=""
                    />
                
                )
            }
        <FormAction handleSubmit={handleSubmit} text="Login" extraClass=""/>
        
      </form>
    )
}
