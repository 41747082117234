import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div>
        <p className="border-b border-gray-300 border-solid mb-2">{`${payload[0].payload.longname}`}</p>
        <p>{`Correct predictions: ${payload[0].value}`}</p>
        <p>{`Wrong predictions: ${payload[1].value}`}</p>
      </div>
    );
  }

  return null;
};

export const StatsBarChart = (props) => {
  return (
    <div style={{ height: "36rem", width: "1240px" }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={1240}
          height={300}
          data={props.data}
          margin={{
            top: 30,
            right: 0,
            left: -30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="5 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip
            wrapperStyle={{
              padding: "16px",
              background: "#ffffff",
              outline: "none",
              border: "1px solid #d3d3d3",
              borderRadius: "4px",
              boxShadow:
                "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)",
            }}
            content={<CustomTooltip />}
          />
          <Legend />
          <Bar dataKey="correct" fill="#02A0CC" />
          <Bar dataKey="wrong" fill="#FF3F0F" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
