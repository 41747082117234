import { gql } from "@apollo/client";

const CREATE_HEAD_TO_HEAD = gql`
  mutation createHeadToHead(
    $gameID: Int!
    $h2hData: JSON!
    $h2hProcessed: Boolean!
    $userID: Int!
    $vsUserID: Int!
    $betPoints: Int!
  ) {
    createHeadToHead(
      data: {
        gameID: $gameID
        h2hData: $h2hData
        h2hProcessed: $h2hProcessed
        userID: $userID
        vsUserID: $vsUserID
        betPoints: $betPoints
      }
    ) {
      data {
        id
        attributes {
          gameID
          h2hData
          h2hProcessed
          userID
          vsUserID
          betPoints
        }
      }
    }
  }
`;
export default CREATE_HEAD_TO_HEAD;
