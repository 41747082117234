import React from 'react'
import { StatsPieChart } from '../Charts/StatsPieChart'

export const TotalPredsChart = (props) => {

  const totalPreds = props.preds.length
  const correctPreds = props.preds.filter( pred => pred.attributes.correctPrediction)
  const totalCorrectPreds = correctPreds.length
  const totalWrongPreds = totalPreds - totalCorrectPreds


  const data = [
    { name: 'Correct Predictions', value: totalCorrectPreds, color: '#02A0CC' },
    { name: 'Wrong Predictions', value: totalWrongPreds, color: '#FF3F0F' },
  ];
  return (
    <StatsPieChart data={data}/>
  )
}
