import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';


const cx = '50%';
const cy = '50%';
const iR = '0%';
const oR = '100%';


export const StatsPieChart = (props) => {

  const data = props.data

  const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" className='text-2xl font-poppins font-extrabold'>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    }
 
  return (
    <ResponsiveContainer width="100%" height="100%" aspect={1/1} >
      <PieChart height={250}>
      <Legend layout="vertical" verticalAlign="top" align="top" />
        <Pie
          dataKey="value"
          startAngle={360}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          fill="#8884d8"
          stroke="none"
          height="50%"
          labelLine={false}
          label={renderCustomizedLabel}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        
      </PieChart>
    </ResponsiveContainer>
  )
}
