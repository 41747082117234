import { gql } from "@apollo/client";

const TEAMS = gql`
  query getTeams {
    teams(pagination: { page: 1, pageSize: 50 }) {
      data {
        id
        attributes {
          team_name
          teamID
          team_data
          team_logo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default TEAMS;

export const getFavTeamLogo = (teams, teamID) => {
  let userFavTeamLogo;
  teams.map((team) => {
    if (team.attributes.teamID === teamID) {
      userFavTeamLogo = team.attributes.team_logo.data.attributes.url;
    }
  });
  return userFavTeamLogo;
};
