import { gql, useQuery } from "@apollo/client";
import React from "react";
import Login from "../components/Login";
import { YoutubeVideoEmbed } from "../components/YoutubeVideoEmbed";
import { useAuthContext } from "../context/AuthContext";
import { getToken } from "../helpers";

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

const PLAYAS = gql`
  query getPlayas {
    mainOptionsPage {
      data {
        attributes {
          introImg {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const OPTIONS_YT_LINK = gql`
  query getOptionsYtLink {
    mainOptionsPage {
      data {
        attributes {
          youtubeHighlightLink
        }
      }
    }
  }
`;

export default function Homepage() {
  const token = getToken();
  const { user, setUser } = useAuthContext();
  const {
    data: playasData,
    error: playasError,
    loading: playasLoading,
  } = useQuery(PLAYAS);
  const {
    data: ytData,
    error: ytError,
    loading: ytLoading,
  } = useQuery(OPTIONS_YT_LINK, { fetchPolicy: "no-cache" });

  let playasUrl = "";
  if (!playasLoading) {
    playasUrl =
      playasData.mainOptionsPage.data.attributes.introImg.data.attributes.url;
  }

  let youtubeLink = {};

  if (!ytLoading) {
    youtubeLink = ytData.mainOptionsPage.data.attributes.youtubeHighlightLink;
  }

  return (
    <div className="w-full flex flex-row flex-wrap">
      <div className="w-full md:w-1/3">
        <div className="flex flex-row flex-wrap">
          <div className="w-full lg:pr-20 md:pr-5 mb-5 md:mb-0">
            {token && user ? (
              <>
                <h2 className="text-2xl text-dark-color-font uppercase py-16">
                  Welcome back playa!
                </h2>
                <img src={`${backendUrl}${playasUrl}`} alt="the_playas" />
              </>
            ) : (
              <>
                <h2 className="text-2xl text-dark-color-font uppercase py-16">
                  Sign in!
                </h2>
                <Login />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w-full md:w-2/3 ">
        <h2 className=" text-2xl text-dark-color-font py-16 font-semibold ">
          Yesterday's Highlights
        </h2>
        <div className="mb-10">
          <YoutubeVideoEmbed youtubeLink={youtubeLink} />
        </div>
      </div>
    </div>
  );
}
