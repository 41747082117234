import React, { useTransition, useState, lazy } from "react";
const VideoPlayer = lazy(() => import('../components/UI/VideoPlayer'));

export const YoutubeVideoEmbed = (props) => {
 
  // useTransition is used to let React know there will be a
  // rerender when the button is pressed.
  const [, startTransition] = useTransition();

  // These two states handle the button press, and
  // the loading of the YouTube iframe.
  const [showVideo, setShowVideo] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);


  return (
 
    <div className='videoRatio'>
      {
        // If the button has not been pressed, and the YouTube
        // video is not loading - keep the button rendered.
      }
      {(!showVideo || !hasLoaded) && (
        <button
          className='thumbnailButton'
          onClick={() => {
            startTransition(() => {
              setShowVideo(true);
            });
          }}
        >
          <div className='videoInner'>
            <img
              alt={props.youtubeLink.title}
              src={props.youtubeLink.image}
              className='thumbnailImage'
              loading="lazy"
            />
            
            <div className="play-toggle">
              <div className="play-circle">
                <i className="play-icon"></i>
              </div>
            </div>
          </div>
        </button>
      )}
      {showVideo && (
        <VideoPlayer videoId={props.youtubeLink.id} setHasLoaded={setHasLoaded} />
      )}
    </div>
 
  );
};
