import React, { useEffect, useState } from "react";
import useCountdown from "../../hooks/useCountdown";
import Tooltip from "../UI/Tooltip";
import { ClockIcon } from "../icons/ClockIcon";
import { LockIcon } from "../icons/LockIcon";
import { GameCardHeaderH2H } from "./GameCardHeaderH2H";

export const GameCardHeader = (props) => {
  let timeLeft = Date.parse(props.dateStart) - Date.now();
  let startTime = new Date(props.dateStart).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  const [gameFinished, setGameFinished] = useState(false);

  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <div
        className={`w-12 show-counter text-xs flex mr-2 ${
          props.gameHasPred
            ? "text-neutral-300"
            : hours < 3 && !props.gameHasPred
            ? "text-red-600 font-semibold"
            : "text-neutral-600"
        }`}
      >
        <DateTimeDisplay value={hours} />
        <p>:</p>
        <DateTimeDisplay value={minutes} />
        <p>:</p>
        <DateTimeDisplay value={seconds} />
      </div>
    );
  };

  const DateTimeDisplay = ({ value, isDanger }) => {
    return (
      <div
        className={
          isDanger ? "countdown danger text-red-600 font-semibold" : "countdown"
        }
      >
        <p>{value}</p>
      </div>
    );
  };

  let tooltipText = `More than 1 day left`;

  if (timeLeft < 86400000 && timeLeft > 0) {
    tooltipText = `Less than 1 day left`;
  }
  if (timeLeft < 43200000 && timeLeft > 0) {
    tooltipText = `Until prediction closes`;
  }

  let customClockCss = "stroke-neutral-300 mr-2 hidden";
  if (timeLeft >= 0) {
    customClockCss = "stroke-neutral-500 mr-2 block";
    if (props.gameHasPred) {
      customClockCss = "stroke-neutral-300 mr-2 block";
    }
  }

  let customLockCss = "stroke-neutral-300 cursor-default";
  if (!props.emptyPredictionState) {
    customLockCss = "stroke-neutral-500 cursor-pointer";
  }
  if (!props.emptyFinalPrediction) {
    customLockCss = "stroke-neutral-300 cursor-default";
  }

  const [days, hours, minutes, seconds] = useCountdown(
    Date.parse(props.dateStart)
  );

  useEffect(() => {
    setGameFinished(props.gameFinished);
  }, [props.gameFinished]);

  return (
    <>
      {!gameFinished && timeLeft > 0 ? (
        <div className="border-b mb-4 border-neutral-200 flex py-2 justify-between items-center">
          <div>
            <p className="text-sm">{startTime}</p>
          </div>
          <div className="flex items-end">
            <GameCardHeaderH2H
              predictionID={props.predictionID}
              gameID={props.gameID}
              gameHasH2h={props.gameHasH2h}
              dateStart={props.dateStart}
            />

            {timeLeft < 0 ? null : (
              <Tooltip text={tooltipText}>
                {timeLeft > 43200000 ? (
                  <ClockIcon customCss={customClockCss} />
                ) : (
                  <ShowCounter
                    hours={hours}
                    minutes={minutes}
                    seconds={seconds}
                  />
                )}
              </Tooltip>
            )}
            <button
              onClick={(e) =>
                props.emptyPredictionState ||
                !props.emptyFinalPrediction ||
                props.gameHasPred
                  ? null
                  : props.handleFinalPrediction(e, props.predisctionState)
              }
            >
              <LockIcon
                customCss={customLockCss}
                emptyFinalPrediction={props.emptyFinalPrediction}
                gameHasPred={props.gameHasPred}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="border-b mb-4 border-neutral-200 flex py-2 justify-between items-center">
          <div className="flex items-end">
            <GameCardHeaderH2H
              predictionID={props.predictionID}
              gameID={props.gameID}
              gameHasH2h={props.gameHasH2h}
              gameFinished={props.gameFinished}
              timeLeft={props.timeLeft}
            />
          </div>
        </div>
      )}
    </>
  );
};
