import { gql, useQuery } from "@apollo/client";
import React from "react";
import Markdown from "react-markdown";
let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

const MAINIMAGE = gql`
  query getMainImage {
    rulesPage {
      data {
        attributes {
          mainImg {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const MAINCONTENT = gql`
  query getMainContent {
    rulesPage {
      data {
        attributes {
          mainContent
        }
      }
    }
  }
`;

export const Rules = () => {
  const {
    data: mainImgData,
    error: mainImgError,
    loading: mainImgLoading,
  } = useQuery(MAINIMAGE);
  const {
    data: mainContentData,
    error: mainContentError,
    loading: mainContentLoading,
  } = useQuery(MAINCONTENT);

  let mainImgUrl = "";
  if (!mainImgLoading) {
    mainImgUrl =
      mainImgData.rulesPage.data.attributes.mainImg.data.attributes.url;
  }
  let mainContent = "";
  if (!mainContentLoading) {
    mainContent = mainContentData.rulesPage.data.attributes.mainContent;
  }

  return (
    <div className="">
      <div className="lg:flex py-16 items-center">
        <h2 className="text-2xl text-dark-color-font uppercase">Rules</h2>
      </div>

      <div className="w-full flex flex-row flex-wrap items-end sm:mb-9 border-b border-gray-300 border-solid"></div>
      <div className="py-12 w-full flex flex-row flex-wrap">
        <div className="w-full md:w-1/3">
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:pr-20 md:pr-5 mb-4">
              <img src={`${backendUrl}${mainImgUrl}`} alt="the_playas" />
            </div>
          </div>
        </div>
        <div className="w-full md:w-2/3">
          <div className="bg-white rounded-xl md:rounded-lg shadow-md p-6">
            <Markdown className="[&>h2]:pb-4 [&>h2]:text-2xl [&>h2]:text-dark-color-font [&>p]:pb-2 [&>ul>li]:list-disc [&>ul>li]:pb-2 [&>ul]:pl-4">
              {mainContent}
            </Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};
