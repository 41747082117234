import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";

const useGamesQuery = (start, end) => {
  const { user, isLoading, setUser } = useAuthContext();
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    setUserID(user?.id);
  }, [user?.id]);

  //start, end are object containing offset and range properties, offset is a number, range is boolean
  const defineGameDate = (dateObj) => {
    const today = new Date();
    if (dateObj.range) {
      today.setDate(today.getDate() + dateObj.offset);
    } else {
      today.setDate(today.getDate() - dateObj.offset);
    }
    today.setUTCHours(-1, 0, 0, -1); //1.value-> -2, poletno vreme ali -1 zimsko, zadnji value -1 = 59sek

    return today.toISOString();
  };

  const startDate = defineGameDate(start);
  const endDate = defineGameDate(end);

  const GAMES = gql`
  query GetGames {
    games(
      pagination: { page: 1, pageSize: 50 }
      filters: {
        date_start: {
          gte: "${startDate}",
          lte: "${endDate}"}
        }
      ) {
      data {
        id,
        attributes {
          date_start,
          teams,
          scores,
          status,
          gameID
        }
      }
    }
    teams(pagination: { page: 1, pageSize: 30 }) {
      data {
        id
        attributes {
          teamID
          team_data
          team_logo {data{attributes{url}}}
        }
      }
    }
    predictions(
      pagination: { page: 1, pageSize: 100 }
      filters: {
        date_start: {
          gte: "${startDate}",
          lte: "${endDate}"
          },
        userID: {
          eq: ${userID},
          }
        }
      ) {
      data {
        id,
        attributes {
          dbID,
          gameID,
          userID,
          predictionTeamID,
          date_start,
          correctPrediction,
          predictionProcessed,
          headToHead
        }
      }
    }
  }
`;

  const [getGames, { loading, error, data }] = useLazyQuery(GAMES);

  useEffect(() => {
    if (user) {
      getGames();
    }
  }, [user, getGames]);

  return { loading, error, data };
};

export default useGamesQuery;
