import React, { useEffect, useState } from "react";
import { StatsLineChart } from "../Charts/StatsLineChart";

export const RankLineChart = (props) => {
  const users = props.users;
  const [rankData, setRankData] = useState([]);

  useEffect(() => {
    const rkData = [];
    const sortedUsers = users.filter((user) => user.attributes.username);

    sortedUsers.forEach((user) => {
      let rankObj = {
        username: "",
        rank: null,
        date: null,
        points: null,
      };
      let stats = user.attributes.stats;
      if (stats !== null) {
        if (stats.length < 6) {
          stats = user.attributes.stats;
        } else {
          stats = user.attributes.stats.slice(1).slice(-5); //-> last 5 days
        }

        stats.forEach((stat) => {
          rankObj.username = user.attributes.username;
          rankObj.rank = stat.rank;
          rankObj.date = new Date(stat.date).getTime();
          rankObj.points = stat.points;

          rkData.push(rankObj);
          rankObj = {};
        });
      }
    });

    setRankData(rkData);
  }, [users]);

  return (
    <StatsLineChart data={rankData} currentUser={props.user} users={users} />
  );
};
