import { createSlice } from "@reduxjs/toolkit";

export const predictionSlice = createSlice({
  name: "predictions",
  initialState: [],
  reducers: {
    addPrediction: (state, action) => {
      const prediction = {
        attributes: {
          dbID: action.payload.dbID,
          gameID: action.payload.gameID,
          userID: action.payload.userID,
          predictionTeamID: action.payload.predictionTeamID,
          predictionProcessed: action.payload.predictionProcessed,
        },
      };

      return [...state, prediction];
    },
  },
});

// this is for dispatch

export const { addPrediction } = predictionSlice.actions;

// this is for configureStore
export default predictionSlice.reducer;
