import { createSlice } from "@reduxjs/toolkit";

export const headToHeadSlice = createSlice({
  name: "headToHeadBoolean",
  initialState: [],
  reducers: {
    setHeadToHeadBoolean: (state, action) => {
      const existingPred = state.find(
        (pred) => pred.predIdentifier === action.payload.predIdentifier
      );

      if (!existingPred) {
        return [...state, action.payload];
      }

      return state;
    },
  },
});

// this is for dispatch

export const { setHeadToHeadBoolean } = headToHeadSlice.actions;

// this is for configureStore
export default headToHeadSlice.reducer;
