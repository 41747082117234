import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSlider from "react-slider";
import CREATE_HEAD_TO_HEAD from "../../constants/createHeadToHead";
import { UPDATE_USER_RES_POINTS } from "../../constants/updateUser";
import { useAuthContext } from "../../context/AuthContext";
import { useSocket } from "../../context/SocketProvider";
import { getToken } from "../../helpers";
import { setHeadToHeadPredictionPoints } from "../../store/HeadToHead/headToHeadPredictionPoints";
import { setHeadToHeadPredictions } from "../../store/HeadToHead/headToHeadPredictions";
import { LockIcon } from "../icons/LockIcon";

const normalizePrediction = (prediction) => {
  // Check if it's a prediction from props.h2hStoredPredictions
  if (
    prediction.userID &&
    prediction.gameID &&
    prediction.betPoints &&
    prediction.vsUserID
  ) {
    return prediction;
  }

  // Normalize the structure for props.h2hSetData.headToHeads.data
  return {
    userID: prediction.attributes.userID,
    gameID: prediction.attributes.gameID,
    betPoints: prediction.attributes.betPoints,
    vsUserID: prediction.attributes.vsUserID,
  };
};

function usePredsArray({ h2hPreds, ...props }) {
  const [userIds, setUserIds] = useState([]);
  const [predsArray, setPredsArray] = useState([]);
  const [predsArrayWithUsername, setPredsArrayWithUsername] = useState([]);
  const [h2hDataUsers, setH2hDataUsers] = useState([]);
  const [usersCombinedArray, setUsersCombinedArray] = useState([]);

  useEffect(() => {
    // Combine arrays when props are updated
    const combinedArray = [];

    // Add objects from props.h2hStoredPredictions
    if (props.h2hStoredPredictions && props.h2hStoredPredictions.length > 0) {
      combinedArray.push(
        ...props.h2hStoredPredictions.map(normalizePrediction)
      );
    }

    // Add values from props.h2hSetData.headToHeads.data
    if (
      props.h2hSetData &&
      props.h2hSetData.headToHeads &&
      props.h2hSetData.headToHeads.data
    ) {
      combinedArray.push(
        ...props.h2hSetData.headToHeads.data.map(normalizePrediction)
      );
    }

    // Set the combined array to state
    setUsersCombinedArray(combinedArray);
  }, [props.h2hStoredPredictions, props.h2hSetData]);

  useEffect(() => {
    if (h2hPreds?.predictions) {
      const userIdsFromPreds = [
        ...new Set(
          h2hPreds.predictions.data.map((pred) => pred.attributes.userID)
        ),
      ];
      setUserIds((prevUserIds) => [
        ...new Set([...prevUserIds, ...userIdsFromPreds]),
      ]);
      setPredsArray(h2hPreds.predictions.data);
    }
  }, [h2hPreds.predictions]);

  useEffect(() => {
    if (usersCombinedArray) {
      const userIdsThatPlacedBet = usersCombinedArray
        .map((userThatBet) => {
          if (userThatBet.gameID === props.gameID) {
            return {
              userID: userThatBet.userID,
              vsUserID: userThatBet.vsUserID,
              betPoints: userThatBet.betPoints,
            };
          }
          return null;
        })
        .filter(Boolean); // Filter out null values

      setH2hDataUsers((prevH2hDataUsers) => {
        // Check uniqueness based on both userID and vsUserID
        const uniqueUsers = [
          ...new Map(
            [...prevH2hDataUsers, ...userIdsThatPlacedBet].map((obj) => [
              `${obj.userID}-${obj.vsUserID}`,
              obj,
            ])
          ).values(),
        ];
        return uniqueUsers;
      });
    }
  }, [props.gameID, usersCombinedArray]);

  const GET_USERS = gql`
    query GetUsers($userIds: [ID!]!) {
      usersPermissionsUsers(filters: { id: { in: $userIds } }) {
        data {
          id
          attributes {
            username
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_USERS, {
    variables: { userIds: userIds },
  });

  useEffect(() => {
    if (data?.usersPermissionsUsers?.data) {
      const relevantUsersData = data.usersPermissionsUsers.data
        .filter((user) => userIds.includes(parseInt(user.id, 10))) // Convert user.id to number
        .map((user) => ({ id: user.id, username: user.attributes.username }));

      const usernameMap = relevantUsersData.reduce((map, user) => {
        map[user.id] = user.username;
        return map;
      }, {});

      const predsArrayWithUsername = predsArray.map((pred) => {
        const userID = pred.attributes.userID;
        const username = usernameMap[parseInt(userID, 10)] || "Unknown"; // Convert userID to number
        return {
          ...pred,
          attributes: {
            ...pred.attributes,
            username,
          },
        };
      });

      setPredsArrayWithUsername(predsArrayWithUsername);
    }
  }, [data, predsArray, userIds]);

  return {
    predsArrayWithUsername: predsArrayWithUsername,
    h2hDataUsers: h2hDataUsers,
  };
}

export const GameCardHeaderH2HContent = (props) => {
  const token = getToken();
  const [socket] = useState(useSocket());
  const { user, isLoading, setUser } = useAuthContext();
  const [userID, setUserID] = useState(null);
  const [createHeadToHead, { loading, error }] =
    useMutation(CREATE_HEAD_TO_HEAD);
  const [updateUserResPoints, { loadingUser, errorUser }] = useMutation(
    UPDATE_USER_RES_POINTS
  );

  const [h2hWasSet, setH2hWasSet] = useState([]);
  const [reservedPointsVal, setReservedPointsVal] = useState(null);
  const [h2hPrediction, setH2hPrediction] = useState({});
  const storedHeadToHeadPredictionPoints = useSelector(
    (state) => state.headToHeadPredictionPoints
  );
  const h2hStoredPredictions = useSelector(
    (state) => state.headToHeadPredictions
  );
  const [pointDiff, setPointDiff] = useState(0);

  const [finishedH2h, setFinishedH2h] = useState([]);

  const dispatch = useDispatch();

  let predsArray = [];

  const [values, setValues] = useState(predsArray.map(() => 0));

  let customLockCss = "stroke-neutral-500 cursor-pointer w-12";

  useEffect(() => {
    setUserID(user?.id);
    setFinishedH2h(props.h2hFinPreds);
  }, [finishedH2h, props.h2hFinPreds, user?.id]);

  const h2hPredsToMap = useCallback(
    (h2hPred) => {
      if (h2hPred.gameID === props.gameID && h2hPred.userID === userID) {
        setH2hPrediction(h2hPred);
      }
      if (h2hPred.userID === userID) {
        setReservedPointsVal(h2hPred.availablePoints);
      }
    },

    [props.gameID, userID]
  );

  useEffect(() => {
    if (user && storedHeadToHeadPredictionPoints) {
      h2hPredsToMap(storedHeadToHeadPredictionPoints);
    }
  }, [user, storedHeadToHeadPredictionPoints, h2hPredsToMap]);

  const usersForH2H = usePredsArray({
    h2hStoredPredictions,
    ...props,
  }).predsArrayWithUsername;

  const usersThatHavePlacedBets = usePredsArray({
    h2hStoredPredictions,
    ...props,
  }).h2hDataUsers;

  const handleHeadToHead = async (e, h2hPrediction) => {
    e.preventDefault();
    e.currentTarget.disabled = true;
    customLockCss = "stroke-neutral-300 cursor-default";
    const headers = {
      Authorization: `${process.env.REACT_APP_BEARER} ${token}`,
      "Content-Type": "application/json",
    };

    const variables = {
      gameID: h2hPrediction.gameID,
      h2hData: {},
      h2hProcessed: false,
      userID: h2hPrediction.userID,
      vsUserID: h2hPrediction.h2hData.vsUserID,
      betPoints: h2hPrediction.h2hData.betPoints,
    };

    const existingRecord = props.h2hSetData.headToHeads.data.find(
      (record) =>
        record.attributes.gameID === variables.gameID &&
        record.attributes.vsUserID === variables.vsUserID
    );
    console.log(existingRecord);
    if (existingRecord) {
      // Record already exists, handle accordingly
      console.log("Record already exists");
    } else {
      // Record doesn't exist, proceed with the mutation
      await createHeadToHead({
        context: {
          headers: headers,
        },
        variables: variables,
      });
    }

    await updateUserResPoints({
      context: {
        headers: headers,
      },
      variables: {
        id: h2hPrediction.userID,
        availablePoints: h2hPrediction.availablePoints,
      },
    });

    let h2hPredictionPointsData = {
      userID: h2hPrediction.userID,
      availablePoints: h2hPrediction.availablePoints,
    };

    let h2hPredictionData = {
      userID: h2hPrediction.userID,
      gameID: h2hPrediction.gameID,
      betPoints: h2hPrediction.h2hData.betPoints,
      vsUserID: h2hPrediction.h2hData.vsUserID,
    };

    dispatch(setHeadToHeadPredictionPoints(h2hPredictionPointsData));
    dispatch(setHeadToHeadPredictions(h2hPredictionData));

    setH2hWasSet([
      ...h2hWasSet,
      {
        isSet: true,
        vsUserID: h2hPrediction.vsUserID,
      },
    ]);

    socket.emit("betHeadToHead", {
      userID: h2hPrediction.userID,
      gameID: h2hPrediction.gameID,
      vsUserID: h2hPrediction.h2hData.vsUserID,
    });
  };

  let timeLeft = Date.parse(props.dateStart) - Date.now();

  const finshedH2hResult = props.h2hFinPreds.headToHeads.data
    .map((h2hFin) => {
      let finH2hRes = {};
      if (
        userID === h2hFin.attributes.userID &&
        h2hFin.attributes.correctH2h !== null
      ) {
        finH2hRes.betPoints = h2hFin.attributes.betPoints;
        finH2hRes.correctH2h = h2hFin.attributes.correctH2h;
        finH2hRes.vsUserID = h2hFin.attributes.vsUserID;
        finH2hRes.gameID = h2hFin.attributes.gameID;
      }
      return finH2hRes;
    })
    .filter((result) => Object.keys(result).length !== 0);

  const mergedArray = usersForH2H
    .map((user) => {
      const matchingResult = finshedH2hResult.find(
        (result) => result.vsUserID === user.attributes.userID
      );

      if (matchingResult) {
        return {
          username: user.attributes.username,
          betPoints: matchingResult.betPoints,
          correctH2h: matchingResult.correctH2h,
          gameID: matchingResult.gameID,
          vsUserID: matchingResult.vsUserID,
        };
      }

      return null; // or handle the case when there is no match
    })
    .filter(Boolean);

  const headToHeadsData = props.h2hFinPreds.headToHeads.data;

  const newArray = mergedArray.map((mergedObj) => {
    const matchingHeadToHead = headToHeadsData.find(
      (headToHead) => headToHead.attributes.userID === mergedObj.vsUserID
    );

    if (matchingHeadToHead) {
      const { betPoints: userBetPoints } = mergedObj;
      const { betPoints: vsUserBetPoints } = matchingHeadToHead.attributes;

      let newBetPoints;

      console.log(userBetPoints);

      console.log(vsUserBetPoints);

      if (userBetPoints > vsUserBetPoints) {
        newBetPoints = vsUserBetPoints;
      } else {
        newBetPoints = userBetPoints; // Double the points for other cases
      }

      return {
        ...mergedObj,
        betPoints: newBetPoints,
      };
    }

    return mergedObj; // If no match is found, return the original object
  });

  console.log(newArray);
  //console.log(props.h2hFinPreds.headToHeads.data);
  //console.log(mergedArray);

  return (
    <>
      {!props.gameFinished && timeLeft > 0 ? (
        <>
          <div>
            {usersForH2H
              ? usersForH2H
                  .filter(
                    (pred) =>
                      userID !== pred.attributes.userID &&
                      !usersForH2H.some(
                        (otherPred) =>
                          otherPred.attributes.predictionTeamID ===
                            pred.attributes.predictionTeamID &&
                          otherPred.attributes.userID === userID
                      )
                  )
                  .map((pred, index) => {
                    const id = pred.id;
                    let value = values[index];
                    let availablePoints = user?.availablePoints;

                    if (reservedPointsVal !== null) {
                      availablePoints = reservedPointsVal;
                    }

                    const hasPlacedBet = usersThatHavePlacedBets.some(
                      (bet) =>
                        bet.userID === userID &&
                        bet.vsUserID === pred.attributes.userID
                    );

                    return (
                      <div
                        key={id}
                        className="border-t border-neutral-200 py-3"
                      >
                        {!hasPlacedBet ? (
                          <>
                            <div className="flex items-center">
                              <div className="flex items-center flex-wrap w-1/2">
                                <div>{`Bet ${pred.attributes.username}!`}</div>
                                <div className="mr-4">{value} points</div>
                              </div>
                              <ReactSlider
                                step={5}
                                min={0}
                                max={availablePoints}
                                className={`w-full h-3 pr-2 bg-gray-200 rounded-md cursor-grab mr-4 slider-${id}`}
                                thumbClassName={`absolute w-5 h-5 cursor-grab bg-main-color rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-color-darker -top-5px slider-${id}`}
                                value={value}
                                onChange={(newValue) => {
                                  const newValues = [...values];
                                  newValues[index] = newValue;
                                  setValues(newValues);
                                }}
                              />
                              <button
                                onClick={(e) =>
                                  handleHeadToHead(e, {
                                    gameID: props.gameID,
                                    h2hData: {
                                      userID: userID,
                                      vsUserID: pred.attributes.userID,
                                      betPoints: value,
                                      h2hProccessed: false,
                                    },
                                    userID: userID,
                                    availablePoints: availablePoints - value,
                                  })
                                }
                              >
                                <LockIcon
                                  customCss={customLockCss}
                                  h2hWasSet={h2hWasSet}
                                />
                              </button>
                            </div>
                          </>
                        ) : (
                          <div className="flex items-center justify-between">
                            <p>
                              {`${
                                usersThatHavePlacedBets.find(
                                  (user) =>
                                    user.vsUserID === pred.attributes.userID &&
                                    userID === user.userID
                                )?.betPoints || "0"
                              } `}
                              points vs {pred.attributes.username}
                            </p>
                            <LockIcon
                              customCss={
                                "stroke-neutral-300 cursor-default w-12"
                              }
                              h2hWasSet={false}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })
              : "loading"}
          </div>
        </>
      ) : (
        <>
          {newArray.map((finished) => {
            return (
              <div key={finished.vsUserID} className="flex items-center">
                <div className="flex items-center flex-wrap w-1/2">
                  {finshedH2hResult[0].correctH2h ? (
                    <div className="flex justify-between w-full">
                      {`${finished.username}`}
                      <span className="text-nice-green-darker">
                        <strong>{`${finished.betPoints}`}</strong>
                      </span>
                    </div>
                  ) : (
                    <div className="flex justify-between w-full">
                      {`${finished.username}`}
                      <span className="text-nice-red-darker">
                        <strong>{`-${finished.betPoints}`}</strong>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
