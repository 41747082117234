import { gql } from "@apollo/client";

const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $about: String
    $favoriteTeamID: Int
    $taunt: String
  ) {
    updateUsersPermissionsUser(
      id: $id
      data: { about: $about, taunt: $taunt, favoriteTeamID: $favoriteTeamID }
    ) {
      data {
        id
        attributes {
          username
          about
          taunt
          favoriteTeamID
        }
      }
    }
  }
`;

export default UPDATE_USER;

export const UPDATE_USER_RES_POINTS = gql`
  mutation updateUserResPoints($id: ID!, $availablePoints: Int!) {
    updateUsersPermissionsUser(
      id: $id
      data: { availablePoints: $availablePoints }
    ) {
      data {
        id
        attributes {
          username
          availablePoints
        }
      }
    }
  }
`;
