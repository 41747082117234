const loginFields=[
  {
      labelText:"Email address",
      labelFor:"email",
      id:"email",
      name:"email",
      type:"email",
      autoComplete:"email",
      isRequired:true,
      placeholder:"Email address"   
  },
  {
      labelText:"Password",
      labelFor:"password",
      id:"password",
      name:"password",
      type:"password",
      autoComplete:"current-password",
      isRequired:true,
      placeholder:"Password"   
  }
]

const signupFields=[
  {
      labelText:"Username",
      labelFor:"username",
      id:"username",
      name:"username",
      type:"text",
      autoComplete:"username",
      isRequired:true,
      placeholder:"Username"   
  },
  {
      labelText:"Email address",
      labelFor:"email",
      id:"email",
      name:"email",
      type:"email",
      autoComplete:"email",
      isRequired:true,
      placeholder:"Email address"   
  },
  {
      labelText:"Password",
      labelFor:"password",
      id:"password",
      name:"password",
      type:"password",
      autoComplete:"current-password",
      isRequired:true,
      placeholder:"Password"   
  },
  {
      labelText:"Confirm Password",
      labelFor:"confirmPassword",
      id:"confirmPassword",
      name:"confirmPassword",
      type:"password",
      autoComplete:"confirmPassword",
      isRequired:true,
      placeholder:"Confirm Password"   
  }
]
const profileSettingsFields=[
  {
    labelText:"Favorite Team",
    labelFor:"favoriteTeam",
    id:"favoriteTeam",
    name:"favoriteTeam",
    type:"select",
    autoComplete:"favoriteTeam",
    isRequired:false,
    placeholder:"Choose your Favorite Team"   
  },
  {
    labelText:"Short Bio",
    labelFor:"shortBio",
    id:"shortBio",
    name:"shortBio",
    type:"textarea",
    autoComplete:"shortBio",
    isRequired:false,
    placeholder:"Short Bio"   
  },
  {
    labelText:"Taunt",
    labelFor:"taunt",
    id:"taunt",
    name:"taunt",
    type:"textarea",
    autoComplete:"taunt",
    isRequired:false,
    placeholder:"Taunt"   
  },
  {
    labelText:"Old Password",
    labelFor:"oldPassword",
    id:"oldPassword",
    name:"oldPassword",
    type:"password",
    autoComplete:"current-password",
    isRequired:false,
    placeholder:"Current Password"
  },
  {
      labelText:"New Password",
      labelFor:"newPassword",
      id:"newPassword",
      name:"newPassword",
      type:"password",
      autoComplete:"",
      isRequired:false,
      placeholder:"New Password"   
  },
  {
      labelText:"Confirm Password",
      labelFor:"confirmPassword",
      id:"confirmPassword",
      name:"confirmPassword",
      type:"password",
      autoComplete:"",
      isRequired:false,
      placeholder:"Confirm Password"   
  },
  {
    labelText:"Avatar",
    labelFor:"avatar",
    id:"avatar",
    name:"avatar",
    type:"file",
    autoComplete:"avatar",
    isRequired:false,
    placeholder:"Avatar"   
  },

]

export {loginFields,signupFields,profileSettingsFields}