import React, { useEffect, useState } from "react";
import { StatsBarChart } from "../Charts/StatsBarChart";

export const TeamsBarChart = (props) => {
  const teams = props.teams;
  const preds = props.preds;
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const ptData = [];
    const predTeamPair = [];

    const predicionAndTeamSorting = async () => {
      await teams.map((team) => {
        predTeamPair.push(
          preds.filter(
            (pred) =>
              pred.attributes.predictionTeamID === team.attributes.teamID
          )
        );
      });

      predTeamPair.map((ptPair) => {
        const teamPredObj = {
          name: "",
          longname: "",
          correct: null,
          wrong: null,
          total: null,
        };
        if (ptPair.length > 0) {
          let team_id = ptPair[0].attributes.predictionTeamID;
          teamPredObj.total = ptPair.length;
          teamPredObj.correct = ptPair.filter(
            (pt) => pt.attributes.correctPrediction
          ).length;
          teamPredObj.wrong =
            ptPair.length -
            ptPair.filter((pt) => pt.attributes.correctPrediction).length;

          teams.map((team) => {
            if (team.attributes.teamID === team_id) {
              teamPredObj.name = team.attributes.team_data.team.code;
              teamPredObj.longname = team.attributes.team_name;
              ptData.push(teamPredObj);
            }
          });
        }
      });

      setChartData(ptData);
    };

    predicionAndTeamSorting();
  }, [teams, preds]);

  return <StatsBarChart data={chartData} />;
};
