import React, { useEffect, useState } from "react";
import useGamesQuery from "../hooks/useGamesQuery";
import { GameCard } from "./elements/GameCard";

export const ListGames = (props) => {
  const user = props.user;
  const [gamesData, setGamesData] = useState(null);
  const { loading, error, data } = useGamesQuery(
    { offset: 0, range: false },
    { offset: 3, range: true }
  );

  useEffect(() => {
    if (data) {
      setGamesData(data);
    }
  }, [loading, data]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error...</p>;

  let groupedGamesByDate;

  if (gamesData) {
    groupedGamesByDate = gamesData.games.data.reduce((groupedGames, game) => {
      const gameDay = new Date(game.attributes.date_start).toLocaleDateString(
        "sl-SI"
      );
      if (groupedGames[gameDay] == null) groupedGames[gameDay] = [];
      groupedGames[gameDay].push(game);
      return groupedGames;
    }, {});
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  return (
    <div>
      {groupedGamesByDate
        ? Object.entries(groupedGamesByDate).map(([key, value], index) => {
            return (
              <div
                key={makeid(5)}
                className="py-12 w-full flex flex-row flex-wrap"
              >
                <div key={makeid(5)} className="w-full md:w-1/3 mb-5 md:mb-0">
                  <h2 className="text-xl md:text-2xl font-medium bg-white rounded-md shadow-sm py-2 px-2 w-1/3 text-center text-dark-color-font">
                    {key}
                  </h2>
                </div>
                <div
                  key={makeid(5)}
                  className="w-full md:w-2/3 flex flex-row flex-wrap content-start grid lg:grid-cols-3 grid-cols-2 gap-4"
                >
                  {value.map((game, index) => {
                    let gameFinished = true;
                    if (
                      (game.attributes.scores.home.points ||
                        game.attributes.scores.visitors.points) === null
                    ) {
                      gameFinished = false;
                    }

                    let visitorId = game.attributes.teams.visitors.id,
                      homeId = game.attributes.teams.home.id,
                      teams = data.teams.data,
                      dbPredictions = data.predictions.data,
                      homeTeamWins,
                      homeTeamLoss,
                      homeStreak,
                      visitorTeamWins,
                      visitorTeamLoss,
                      visitorStreak,
                      homeLogo,
                      visitorLogo,
                      homePred,
                      visitorPred,
                      homeTeam,
                      visitorTeam;

                    teams.map((team) => {
                      if (homeId === team.attributes.teamID) {
                        homeTeamWins = team.attributes.team_data.win.total;
                        homeTeamLoss = team.attributes.team_data.loss.total;
                        if (team.attributes.team_data.winStreak === true) {
                          homeStreak = `W ${team.attributes.team_data.streak}`;
                        } else {
                          homeStreak = `L ${team.attributes.team_data.streak}`;
                        }
                        homeLogo = team.attributes.team_logo.data;
                        if (homeLogo != null) {
                          homeLogo = Object.values(homeLogo.attributes);
                          homeLogo = homeLogo[1];
                        }
                        homePred = {
                          dbID: parseInt(game.id),
                          gameID: game.attributes.gameID,
                          userID: user?.id,
                          predictionTeamID: homeId,
                          predictionProcessed: false,
                          home: true,
                        };
                        homeTeam = {
                          homeId,
                          homeStreak,
                          homeLogo,
                          homePred,
                          homeTeamWins,
                          homeTeamLoss,
                        };
                      }
                      if (visitorId === team.attributes.teamID) {
                        visitorTeamWins = team.attributes.team_data.win.total;
                        visitorTeamLoss = team.attributes.team_data.loss.total;

                        if (team.attributes.team_data.winStreak === true) {
                          visitorStreak = `W ${team.attributes.team_data.streak}`;
                        } else {
                          visitorStreak = `L ${team.attributes.team_data.streak}`;
                        }
                        visitorLogo = team.attributes.team_logo.data;
                        if (visitorLogo != null) {
                          visitorLogo = Object.values(visitorLogo.attributes);
                          visitorLogo = visitorLogo[1];
                        }
                        visitorPred = {
                          dbID: parseInt(game.id),
                          gameID: game.attributes.gameID,
                          userID: user?.id,
                          predictionTeamID: visitorId,
                          home: false,
                          predictionProcessed: false,
                        };
                        visitorTeam = {
                          visitorId,
                          visitorStreak,
                          visitorLogo,
                          visitorPred,
                          visitorTeamWins,
                          visitorTeamLoss,
                        };
                      }
                    });

                    return (
                      <div key={makeid(5)} className="h-full">
                        <GameCard
                          gameKey={makeid(5)}
                          gameFinished={gameFinished}
                          game={game}
                          visitor={visitorTeam}
                          home={homeTeam}
                          dbPredictions={dbPredictions}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};
