export default function FormAction({
  handleSubmit,
  type='Button',
  action='submit',
  extraClass,
  text
}){
  return(
      <>
      {
          type==='Button' ?
          <button
              type={action}
              className={`group relative w-full flex justify-center p-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-main-color hover:bg-main-color-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-color-darker mt-6 ${extraClass}`}
              onSubmit={handleSubmit}
          >
              {text}
          </button>
          :
          <></>
      }
      </>
  )
}