import { gql, useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { getToken, removeToken } from "../helpers";
import { GamesIcon } from "./icons/GamesIcon";
import { PlayersIcon } from "./icons/PlayersIcon";
import { RulesIcon } from "./icons/RulesIcon";
import { SettingsIcon } from "./icons/SettingsIcon";
import { SignOutIcon } from "./icons/SignOutIcon";
import { SignUpIcon } from "./icons/SignUpIcon";
import { StatsIcon } from "./icons/StatsIcon";
import { UserIcon } from "./icons/UserIcon";

import { getTypenameFromStoreObject } from "@apollo/client/cache/inmemory/helpers";
import DropDownMenu from "./UI/DropdownMenu";

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}
const LOGO = gql`
  query getLogo {
    mainOptionsPage {
      data {
        attributes {
          siteLogo {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
export default function SiteHeader() {
  const { user, setUser } = useAuthContext();
  const navigate = useNavigate();
  const token = getToken();
  const handleLogout = () => {
    removeToken();
    navigate("/", { replace: true });
    setUser({});
  };

  const { data, error, loading } = useQuery(LOGO);
  let logoUrl = "";
  if (!loading) {
    logoUrl = data.mainOptionsPage.data.attributes.siteLogo.data.attributes.url;
  }

  const dropDownMenuItems = [
    {
      mainGroup: [
        {
          menuItemTitle: "Stats",
          menuItemPath: "/profile_stats",
          menuItemIcon: <StatsIcon />,
        },
      ],
    },
    {
      settingsGroup: [
        {
          menuItemTitle: "Settings",
          menuItemPath: "/profile_settings",
          menuItemIcon: <SettingsIcon />,
        },
      ],
    },
    {
      signoutGroup: [
        {
          menuItemTitle: "Sign Out",
          menuItemPath: handleLogout,
          menuItemIcon: <SignOutIcon />,
        },
      ],
    },
  ];

  return (
    <div className="w-full flex flex-row flex-wrap items-center pb-6 border-b-4 border-dark-color-font border-solid">
      <div className="w-full lg:w-1/3 mb-5 lg:mb-0">
        <Link to="/" className="text-4xl font-bold flex items-start flex-col">
          {
            <img
              src={`${backendUrl}${logoUrl}`}
              alt="Airballers logo"
              className="h-auto max-h-32 mt-auto mr-6"
            />
          }
        </Link>
      </div>
      <div className="w-full lg:w-2/3">
        <div className="lg:justify-end items-center justify-between flex">
          <Link
            to="/players"
            className="flex lg:ml-3 lg:mr-6 items-center lg:px-2 text-dark-color-font"
          >
            <PlayersIcon />
            Leaderboard
          </Link>
          <Link
            to="/rules"
            className="flex lg:ml-3 lg:mr-6 items-center lg:px-2 text-dark-color-font"
          >
            <RulesIcon />
            Rules
          </Link>
          {token ? (
            <Link
              to="/profile"
              className="flex lg:ml-3 lg:mr-6 items-center lg:px-2 text-dark-color-font"
            >
              <GamesIcon />
              Games
            </Link>
          ) : (
            <></>
          )}

          {token ? (
            <>
              {user ? (
                <DropDownMenu
                  menuTitle={user.username}
                  mainIcon={<UserIcon />}
                  menuItems={dropDownMenuItems}
                ></DropDownMenu>
              ) : (
                <></>
              )}
            </>
          ) : (
            <Link to="/signup" className="flex lg:ml-3 items-center lg:px-2">
              <SignUpIcon />
              Signup
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
