import { useMutation } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import CREATE_PREDICTION from "../../constants/createPrediction";
import { useAuthContext } from "../../context/AuthContext";
import { getToken } from "../../helpers";

import { useSocket } from "../../context/SocketProvider";
import { addPrediction } from "../../store/predictionSlice";
import { GameCardHeader } from "./GameCardHeader";

let backendUrl;
if (process.env.NODE_ENV === "development") {
  backendUrl = process.env.REACT_APP_BACKENDURL_DEV;
}
if (process.env.NODE_ENV === "production") {
  backendUrl = process.env.REACT_APP_BACKENDURL_PROD;
}

export const GameCard = (props) => {
  const token = getToken();
  const [socket] = useState(useSocket());
  const { user, isLoading, setUser } = useAuthContext();
  const [predID, setPredID] = useState("");
  const [gameHasPred, setGameHasPred] = useState(false);
  const [gameHasH2h, setGameHasH2H] = useState(false);
  const [activeClass, setActiveClass] = useState("");
  const [predisctionState, setPredictionState] = useState({});
  const [finalPrediction, setFinalPrediction] = useState({});
  const [createPrediction, { loading, error }] = useMutation(CREATE_PREDICTION);
  const dbPredictions = props.dbPredictions;
  const gameFinished = props.gameFinished;
  const emptyFinalPrediction = Object.keys(finalPrediction).length === 0;
  const emptyPredictionState = Object.keys(predisctionState).length === 0;
  const game = props.game;

  const storedPrediction = useSelector((state) => state.predictions);
  const dispatch = useDispatch();

  let timeLeft = Date.parse(game.attributes.date_start) - Date.now();

  const activeClasses =
    "bg-neutral-100 border-transparent border-b-4 border-neutral-500";
  const finalClasses =
    "bg-gradient-to-t from-main-color-lighter to-main-color-lighter-2 border-main-color-darker border-b-4";

  const correctClasses =
    "bg-nice-green-lighter border-transparent border-b-4 border-nice-green-darker bg-gradient-to-t from-nice-green-lighter-2 to-nice-green-lighter";
  const incorrectClasses =
    "bg-nice-red-lighter border-transparent border-b-4 border-nice-red-darker bg-gradient-to-t from-nice-red-lighter-2 to-nice-red-lighter";

  const homeTeam = props.home;
  const visitorTeam = props.visitor;

  let borderVisitorClasses =
    "mr-px p-1 sm:p-4 block sm:shrink-0 w-1/2 flex justify-center items-center flex-col border-b-4 border-transparent";
  let borderHomeClasses =
    "ml-px p-1 sm:p-4 block sm:shrink-0 w-1/2 flex justify-center items-center flex-col border-b-4 border-transparent";

  if (activeClass === `${game.id}-v`) {
    borderVisitorClasses = `${borderVisitorClasses} ${activeClasses}`;
  }
  if (activeClass === `${game.id}-v-final`) {
    borderVisitorClasses = `${borderVisitorClasses} ${finalClasses}`;
  }
  if (activeClass === `${game.id}-h`) {
    borderHomeClasses = `${borderHomeClasses} ${activeClasses}`;
  }
  if (activeClass === `${game.id}-h-final`) {
    borderHomeClasses = `${borderHomeClasses} ${finalClasses}`;
  }

  if (activeClass === `${game.id}-h-correct`) {
    borderHomeClasses = `${borderHomeClasses} ${correctClasses}`;
  }
  if (activeClass === `${game.id}-h-incorrect`) {
    borderHomeClasses = `${borderHomeClasses} ${incorrectClasses}`;
  }
  if (activeClass === `${game.id}-v-correct`) {
    borderVisitorClasses = `${borderVisitorClasses} ${correctClasses}`;
  }
  if (activeClass === `${game.id}-v-incorrect`) {
    borderVisitorClasses = `${borderVisitorClasses} ${incorrectClasses}`;
  }

  let hoverClasses =
    "cursor-pointer hover:bg-neutral-100 hover:border-neutral-500";

  if (gameFinished || !emptyFinalPrediction || gameHasPred || timeLeft < 0) {
    hoverClasses = "";
  }

  const mapPreds = useCallback(
    (predsToMap) => {
      predsToMap.map((dbPred) => {
        if (
          user.id === dbPred.attributes.userID &&
          game.attributes.gameID === dbPred.attributes.gameID
        ) {
          setGameHasPred(true);
          if (typeof dbPred.id !== "undefined") {
            setPredID(dbPred.id);
          }
          if (dbPred.attributes.headToHead) {
            setGameHasH2H(true);
          }
          let dbID = dbPred.attributes.dbID;
          if (gameFinished) {
            if (
              dbPred.attributes.correctPrediction &&
              game.attributes.teams.home.id ===
                dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-h-correct`);
            }
            if (
              !dbPred.attributes.correctPrediction &&
              game.attributes.teams.home.id ===
                dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-h-incorrect`);
            }
            if (
              dbPred.attributes.correctPrediction &&
              game.attributes.teams.visitors.id ===
                dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-v-correct`);
            }
            if (
              !dbPred.attributes.correctPrediction &&
              game.attributes.teams.visitors.id ===
                dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-v-incorrect`);
            }
          }

          if (!gameFinished) {
            if (
              game.attributes.teams.home.id ===
              dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-h-final`);
            }
            if (
              game.attributes.teams.visitors.id ===
              dbPred.attributes.predictionTeamID
            ) {
              setActiveClass(`${dbID}-v-final`);
            }
          }
        }
      });
    },
    [
      user,
      game.attributes.gameID,
      game.attributes.teams.home.id,
      game.attributes.teams.visitors.id,
      gameFinished,
    ]
  );

  useEffect(() => {
    if (user && dbPredictions) {
      mapPreds(dbPredictions);
    }
    if (user && storedPrediction) {
      mapPreds(storedPrediction);
    }
  }, [user, dbPredictions, storedPrediction, mapPreds]);

  const handlePrediction = (e, prediction) => {
    const index = e.currentTarget.getAttribute("data-id");
    setActiveClass(index);
    setPredictionState(prediction);
  };

  const handleFinalPrediction = async (e, prediction) => {
    e.preventDefault();
    setGameHasPred(true);
    setFinalPrediction(prediction);
    setActiveClass(`${activeClass}-final`);

    await createPrediction({
      context: {
        headers: {
          Authorization: `${process.env.REACT_APP_BEARER} ${token}`,
          "Content-Type": "application/json",
        },
      },
      variables: {
        dbID: prediction.dbID,
        gameID: prediction.gameID,
        userID: prediction.userID,
        predictionTeamID: prediction.predictionTeamID,
        date_start: game.attributes.date_start,
        headToHead: prediction.headToHead,
      },
    });

    socket.emit("checkHeadToHead", {
      gameID: prediction.gameID,
      userID: prediction.userID,
      predictionTeamID: prediction.predictionTeamID,
    });

    dispatch(addPrediction(prediction));
  };

  return (
    <div
      key={props.gameKey}
      className="p-2 bg-white shadow-sm flex flex-col h-full rounded-md"
    >
      <GameCardHeader
        dateStart={game.attributes.date_start}
        gameHasPred={gameHasPred}
        gameHasH2h={gameHasH2h}
        emptyFinalPrediction={emptyFinalPrediction}
        emptyPredictionState={emptyPredictionState}
        predisctionState={predisctionState}
        predictionID={predID}
        gameID={game.attributes.gameID}
        gameFinished={gameFinished}
        handleFinalPrediction={handleFinalPrediction}
      />

      <div className="w-full h-full flex items-stretch">
        <div
          className={`${hoverClasses} ${borderVisitorClasses}`}
          onClick={(e) =>
            (gameFinished && !emptyFinalPrediction) ||
            gameHasPred ||
            gameFinished ||
            timeLeft < 0
              ? null
              : handlePrediction(e, visitorTeam.visitorPred)
          }
          data-id={`${game.id}-v`}
        >
          <img
            src={`${backendUrl}${visitorTeam.visitorLogo}`}
            alt={game.attributes.teams.visitors.nickname}
            className="max-w-full h-auto max-h-20 object-contain w-full pb-1.5 mt-auto"
          />
          {gameFinished ? (
            <h3 className="mt-auto justify-center">
              {game.attributes.scores.visitors.points}
            </h3>
          ) : null}
          {!gameFinished ? (
            <div className="text-center mt-auto">
              <div className="font-light text-xs sm:text-base">
                {visitorTeam.visitorTeamWins} - {visitorTeam.visitorTeamLoss}
              </div>
              <div className="font-semibold text-xs sm:text-base">
                {visitorTeam.visitorStreak}
              </div>
            </div>
          ) : null}
        </div>
        <div
          className={`${hoverClasses} ${borderHomeClasses}`}
          onClick={(e) =>
            (gameFinished && !emptyFinalPrediction) ||
            gameHasPred ||
            gameFinished ||
            timeLeft < 0
              ? null
              : handlePrediction(e, homeTeam.homePred)
          }
          data-id={`${game.id}-h`}
        >
          <img
            src={`${backendUrl}${homeTeam.homeLogo}`}
            alt={game.attributes.teams.home.nickname}
            className="max-w-full h-auto max-h-20 object-contain w-full pb-1.5 mt-auto"
          />
          {gameFinished ? (
            <h3 className="mt-auto justify-center">
              {game.attributes.scores.home.points}
            </h3>
          ) : null}
          {!gameFinished ? (
            <div className="text-center mt-auto">
              <div className="font-light text-xs sm:text-base">
                {homeTeam.homeTeamWins} - {homeTeam.homeTeamLoss}
              </div>
              <div className="font-semibold text-xs sm:text-base">
                {homeTeam.homeStreak}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
